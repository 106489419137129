import { useFetch } from "../../../../../hooks/useFetch";

import { User } from "../../../../../utils/interfaces/User";
import { Results } from "../../../../../utils/interfaces/Results";

import { EnvironmentVariables } from "../../../../../utils/Environment";

export const useProfileData = () => {
    const EndPoints = EnvironmentVariables.EndPoints;

    const { runFetchPost } = useFetch();

    const addDetails = async (user: any) => {
        const results: Results = await runFetchPost(user, EndPoints.User.UpdateUser);

        return results;
    }

    const addImage = async (image: any) => {
        const results: Results = await runFetchPost(image, EndPoints.User.UpdateImage);

        return results;
    }

    const addPassword = async (password: any) => {
        const results: Results = await runFetchPost(password, EndPoints.User.UpdatePassword);

        return results;
    }

    const buildDetails = (id: number,
        firstName: string,
        lastName: string,
        gender: string,
        dob: string,
        email: string,
        cell: string,
        bio: string,
        username: string,
        address: string) => {
        return {
            ID: id,
            FirstName: firstName,
            LastName: lastName,
            Gender: gender,
            DateOfBirth: dob,
            Email: email,
            CellphoneNumber: cell,
            Bio: bio,
            Username: username,
            Address: address
        } as User;
    }

    return { addDetails, addPassword, buildDetails, addImage };
}