import React, { useState, useEffect } from "react";

import ModalBox from "../../shared/ModalBox";
import DialogBox from "../../shared/DialogBox";

import { useFetch } from "../../hooks/useFetch";
import { useAccessModals } from "./hooks/useAccessModals";

import { EnvironmentVariables } from "../../utils/Environment";
import { Results } from "../../utils/interfaces/Results";
import { Article } from "../../utils/interfaces/Article";

import { TopicNames } from "../../Topics";

import { Loader } from "../../shared/Loader";

import { NavMenu } from "./shared/NavMenu";
import { CardRibbon } from "./components/CardRibbon";

import { useDispatch } from "react-redux";
import { setCurrentArticle, setPreviewUser } from "../../utils/redux/Actions/MainActions";

import { useNavigate } from "react-router-dom";
import { FooterMenu } from "./shared/FooterMenu";
import UserRibbon from "../../shared/UserRibbon";
import { User } from "../../utils/interfaces/User";

const BlogsScreen: React.FC<{}> = (): JSX.Element => {
    const EndPoints = EnvironmentVariables.EndPoints;

    // pop ups and modals
    const [ModalHeader, setModalHeader] = useState("");
    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [SelectedTopic, setSelectedTopic] = useState("All");

    // boolean states
    const [ModalStatus, setModalStatus] = useState(false);
    const [DialogStatus, setDialogStatus] = useState(false);
    const [IsLoadingArticles, setIsLoadingArticles] = useState(true);

    // data state props
    const [UsersList, setUsersList] = useState<User[]>([]);
    const [SearchItems, setSearchItems] = useState<any[]>([]);

    const [RecentList, setRecentList] = useState<Article[]>([]);
    const [ArticlesList, setArticlesList] = useState<Article[]>([]);
    const [FilteredArticles, setFilteredArticles] = useState<Article[]>([]);

    // hooks
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { runFetchGet, runFetchPost } = useFetch();

    useEffect(() => {
        updateTitle("The Blog | Publishing Articles | Write and Earn | Publish Books");

        getArticles();
    }, []);

    const getArticles = async () => {
        const getResults: Results = await runFetchGet(EndPoints.Article.GetArticles);

        if (getResults.Error) {
            setUpDialogValues(true, "Error", getResults.ErrorDetail);
        } else {
            setArticlesList(getResults.Results.AllArticles);
            setFilteredArticles(getResults.Results.AllArticles);

            if (getResults.Results.RecentArticles.length > 0) {
                setRecentList(getResults.Results.RecentArticles);
            } else {
                setRecentList([...RecentList, getResults.Results.AllArticles[getResults.Results.AllArticles.length - 1]])
            }

            setIsLoadingArticles(false);

        }
    }

    const updateTitle = (title: string) => {
        document.title = title;
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const closeModal = () => {
        setModalStatus(false);
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    const search = async (event: any) => {
        const value = (event.target as HTMLInputElement).value;

        if (value.length >= 3) {
            let list: Article[] = FilteredArticles.filter((item: Article) => {
                return (item.ArticleName.toLowerCase().match(value.toLowerCase()));
            });

            setSearchItems(list);

            const results: Results = await runFetchPost({ FirstName: value.toLowerCase() }, EndPoints.User.SearchUser);

            if (results.Results.length > 0)
                setUsersList(results.Results);
            else
                setSearchItems([]);

        } else {
            setUsersList([]);
            setSearchItems([]);
        }
    }

    const openArticle = async (item: any) => {
        if (item.FirstName) {
            const results: Results = await runFetchPost({ ID: item.ID }, EndPoints.User.GetUser);

            if (results.Error) {
                setUpDialogValues(true, "Error", results.ErrorDetail);
            } else {
                dispatch(setPreviewUser(results.Results));
                navigate("/preview/" + results.Results.User.FirstName + " " + results.Results.User.LastName);
            }
        } else {
            dispatch(setCurrentArticle(item));
            navigate("/article-view/" + item.ID);
        }
    }

    const filterArticles = (topic: string) => {
        const list: Article[] = ArticlesList.filter((item: Article) => {
            return (item.TopicName == topic);
        });

        setSelectedTopic(topic);

        if (list.length > 0)
            setFilteredArticles(list);
        else
            setFilteredArticles(ArticlesList);
    }

    const viewProfile = async (user: any) => {
        const results: Results = await runFetchPost({ ID: user.ID }, EndPoints.User.GetUser);

        if (results.Error) {
            setUpDialogValues(true, "Error", results.ErrorDetail);
        } else {
            dispatch(setPreviewUser(results.Results));
            navigate("/preview/" + results.Results.User.FirstName + " " + results.Results.User.LastName);
        }
    }

    return <>
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />
        <ModalBox Header={ModalHeader} closeModal={closeModal} Status={ModalStatus} />

        <NavMenu setModalStatus={setModalStatus} setModalHeader={setModalHeader} />

        {!IsLoadingArticles && <section>
            <div className="search-section-container">
                <div className="search-section-content">
                    <div className="search-content-container">
                        <div className="search-input">
                            <input type="text" placeholder="look up articles, books and authors" onChange={(event: any) => search(event)} />
                        </div>
                        {(SearchItems.length > 0 || UsersList.length > 0) && <div className="search-results">
                            <ul>
                                {SearchItems.map((item: any, index: number) => {
                                    return <li onClick={() => openArticle(item)} key={index}>
                                        {item.ArticleName}</li>
                                })}
                            </ul>
                            <UserRibbon List={UsersList} viewProfile={viewProfile} />
                        </div>}
                    </div>
                </div>
            </div>
        </section>}

        {!IsLoadingArticles ? <section>
            <div className="recently-published-container">
                <div className="recently-published-content">
                    <div className="recently-content-container">
                        <h4>Recently Published</h4>

                        <div className="recently-published-scrollable-horizontally">
                            <CardRibbon openArticle={openArticle}
                                ItemsList={RecentList}
                                MaxCount={15} />
                        </div>
                        <br />
                        <h4>All Articles</h4>
                        <div className="all-topics">
                            {TopicNames.map((topic: string, index) => {
                                return <span className={SelectedTopic == topic ? "active-topic" : ""} onClick={() => filterArticles(topic)} key={index}>{topic}</span>
                            })}
                        </div>
                        <div className="recently-published-scrollable">
                            <CardRibbon openArticle={openArticle}
                                ItemsList={FilteredArticles}
                                MaxCount={47} />
                        </div>
                        <br /><br />
                    </div>
                </div>
            </div>
        </section> : <div className="loader-container">
            <Loader />
        </div>}
        <br /><br />
        <FooterMenu setModalStatus={setModalStatus} setModalHeader={setModalHeader} />
    </>
}

export default BlogsScreen;