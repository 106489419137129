import React, { useState } from "react";

import NavBar from "../shared/NavBar";
import Footer from "../shared/Footer";
import MainBtn from "../shared/MainBtn";
import ModalBox from "../shared/ModalBox";

import { useNavigate } from "react-router-dom";


import MainBanner from "../assets/images/main_banner_2.jpeg";
import ContentShare from "../shared/ContentShare";

const ContactUsScreen: React.FC<{}> = (): JSX.Element => {
    const navigate = useNavigate();

    const [ModalHeader, setModalHeader] = useState("");

    const [ModalStatus, setModalStatus] = useState(false);

    const signUpUser = () => {
        setModalStatus(true);
        setModalHeader("Register");
    }

    const closeModal = () => {
        setModalStatus(false);
    }

    return <>
        <NavBar />
        <ModalBox Header={ModalHeader} closeModal={closeModal} Status={ModalStatus} />
        <section>
            <div className="contact-us-banner">
                <img src={MainBanner} alt="main banner" />
            </div>
            <div className="contact-us-container">
                <div className="contact-us-content">
                    <h4>About Writing and Earning</h4>
                    <div className="detailing-write-earn">
                        <p>There's literally too many people with excellent thoughts, excellent ideas, visions, plans that can permanently transform societies the world over in a positive way. However, most of these thoughts and ideas, visions and plans
                            mostly remain dormant, unused, unheard and ineffective - and therefore mostly useless.
                        </p>
                        <p>We are presenting to you an opportunity to build a follower base for your content, articles that is. Imagine if you could deliver in writing to the general pulblic your thoughts, your most astounding and
                            influential thoughts, and not only that but if you could also get <b>paid</b> for it.
                        </p>
                        <p>We are asking you, inviting you to share your thoughts with everyone - to take your message and ideas to everyone. You may just hold the key to transforming lives the world over. You will do this while getting <b>paid</b>.
                        </p>
                        <p>We want articles on different topics, anything from sports, current affairs, religion, employment, uneployment, etc.</p>
                        <h6>We will pay based on the following.</h6>
                        <ul>
                            <li>Articles <b>@ R10.00/Article</b> </li>
                            <li>Views on Articles <b>@ R20.00/200 Views</b></li>
                            <li>Likes on Articles <b>@ R20.00/200 Likes</b></li>
                            <li>Comments on Articles <b>@ R1.00/ Unique Comment</b></li>
                        </ul>
                        <p><b>You can only submit one article per week.</b></p>
                        <MainBtn BtnText="Sign Up Today" btnOnclickHandler={signUpUser} />
                        <br />
                    </div>
                    <ContentShare url="write-earn" />
                </div>
            </div>
        </section>
        <Footer />
    </>
}

export default ContactUsScreen;