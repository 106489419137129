import { useFetch } from "../../../../../hooks/useFetch";

import { Results } from "../../../../../utils/interfaces/Results";
import { Followers } from "../../../../../utils/interfaces/Followers";

import { EnvironmentVariables } from "../../../../../utils/Environment";

export const useFollowersData = () => {
    const EndPoints = EnvironmentVariables.EndPoints;

    const { runFetchPost } = useFetch();

    const addFollower = async (follower: Followers) => {
        const results: Results = await runFetchPost(follower, EndPoints.Follower.CreateFollower);

        return results;
    }

    const deleteFollower = async (follower: Followers) => {
        const results: Results = await runFetchPost(follower, EndPoints.Follower.DeleteFollower);

        return results;
    }

    const buildFollower = (aid: number, id: number) => {
        return {
            AuthorID: aid,
            UserID: id
        }
    }

    return { addFollower, deleteFollower, buildFollower };
}