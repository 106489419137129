import React, { useState, useEffect } from "react";

import { NavMenu } from "../../shared/NavMenu";
import DialogBox from "../../../../shared/DialogBox";
import { ImageView } from "../../shared/ImageView";
import ModalBox from "../../../../shared/ModalBox";
import { FooterMenu } from "../../shared/FooterMenu";

// prime faces 
import { TabView, TabPanel } from "primereact/tabview";

import { Results } from "../../../../utils/interfaces/Results";
import { Article } from "../../../../utils/interfaces/Article";
import { Followers } from "../../../../utils/interfaces/Followers";
import { IFavourite } from "../../../../utils/interfaces/IFavourite";

import { useDispatch, useSelector } from "react-redux";

import BannerTwo from "../../../../assets/images/main_banner_2.jpeg";

import FavouritesRibbon from "./components/Favourites";
import ArticlesRibbon from "./components/ArticlesRibbon";
import FollowersRibbon from "./components/FollowersRibbon";

import { useNavigate } from "react-router-dom";

import { EnvironmentVariables } from "../../../../utils/Environment";

import MainBtn from "../../../../shared/MainBtn";

import { useFetch } from "../../../../hooks/useFetch";
import { setCurrentArticle, setPreviewUser } from "../../../../utils/redux/Actions/MainActions";



export const ProfilePreview: React.FC<{}> = (): JSX.Element => {
    const EndPoints = EnvironmentVariables.EndPoints;
    const ImagePath = EnvironmentVariables.URLs.AppUrl + "/images/";

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { runFetchPost } = useFetch();

    const [IsFollower, setIsFollower] = useState(false);
    const [ModalStatus, setModalStatus] = useState(false);
    const [DialogStatus, setDialogStatus] = useState(false);
    const [IsUnFollowing, setIsUnFollowing] = useState(false);

    const [CreatingFollower, setCreatingFollower] = useState(false);

    const [SelectedImage, setSelectedImage] = useState("");

    const [ModalHeader, setModalHeader] = useState("");
    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const PreviewUser: any = useSelector((state: any) => state.PreviewUser);
    const Authenticated: number = useSelector((state: any) => state.Authenticated);

    useEffect(() => {
        if (PreviewUser !== undefined) {
            const follower = PreviewUser.Followers?.find((item: any) => {
                return (item.UserID == Authenticated);
            });

            if (follower)
                setIsFollower(follower);
        }
    }, [ModalStatus]);

    const followAuthor = async () => {
        if (Authenticated > 0) {
            if (IsFollower) {
                setIsUnFollowing(true);
                setUpDialogValues(true, "Warning", "Are you sure you want to unfollow " + PreviewUser.User.FirstName + " " + PreviewUser.User.LastName);
            } else {
                completeFollowRequest(false);
            }
        } else {
            setUpDialogValues(true, "Error", "Please sign in first.");
        }
    }

    const completeFollowRequest = async (type: boolean) => {
        setCreatingFollower(true);

        const params: Followers = {} as Followers;
        let FollowerResults: Results = {} as Results;

        params.UserID = Authenticated;
        params.AuthorID = PreviewUser.User.ID;

        if (type) {
            FollowerResults = await runFetchPost(params, EndPoints.Follower.DeleteFollower);
        } else {
            FollowerResults = await runFetchPost(params, EndPoints.Follower.CreateFollower);
        }

        if (FollowerResults.Error) {
            setUpDialogValues(true, "Error", FollowerResults.ErrorDetail);
        } else {
            if (!IsUnFollowing)
                setUpDialogValues(true, "Success", "You are now following " + PreviewUser.User.FirstName + " " + PreviewUser.User.LastName + ". You will be notified when they publish new articles and when there are new comments on their articles.");
            else
                setUpDialogValues(true, "Success", "You are no longer following " + PreviewUser.User.FirstName + " " + PreviewUser.User.LastName + ". You will not be notified when they publish new articles and when there are new comments on their articles.");
        }

        setCreatingFollower(false);
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const viewArticle = (article: Article) => {
        dispatch(setCurrentArticle({} as Article));
        navigate("/article-view/" + article.ID);
    }

    const viewProfile = async (user: any) => {
        const results: Results = await runFetchPost({ ID: user.UserID }, EndPoints.User.GetUser);

        if (results.Error) {
            setUpDialogValues(true, "Error", results.ErrorDetail);
        } else {
            const follower = results.Results.Followers.find((item: any) => {
                return (item.UserID == Authenticated);
            });

            if (follower)
                setIsFollower(follower);
            else
                setIsFollower(false);

            dispatch(setPreviewUser(results.Results));
            navigate("/preview/" + results.Results.User.FirstName + " " + results.Results.User.LastName);
        }
    }

    const toggleArticle = (favourite: IFavourite) => {
        navigate("/article-view/" + favourite.ArticleID);
    }

    const confirmDecision = (status: boolean) => {
        setIsUnFollowing(status)
        closeDialog();

        if (status)
            completeFollowRequest(true);
    }

    const toggleFullView = () => {
        setSelectedImage(ImagePath + PreviewUser.User.ImageName);

    }

    const closeViewer = () => {
        setSelectedImage("");
    }

    const closeModal = () => {
        setModalStatus(false);
    }

    return <>
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            confirmDecision={confirmDecision}
            isConditional={IsUnFollowing}
            DialogHeader={DialogHeader} />

        <NavMenu setModalHeader={setModalHeader} setModalStatus={setModalStatus} />
        <ModalBox Header={ModalHeader} closeModal={closeModal} Status={ModalStatus} />

        <section>
            {SelectedImage !== "" && <ImageView closeViewer={closeViewer} imagePath={SelectedImage} />}
            <div className="app-profile-container">
                <div className="app-profile-content">
                    <div className="app-profile-banner">
                        <img src={BannerTwo} alt="banner" />
                    </div>
                    {PreviewUser.User !== undefined ?
                        <div className="app-profile-pic-container" onClick={toggleFullView} style={{ background: "url(" + ImagePath + PreviewUser.User.ImageName + ")" }}></div>
                        :
                        <div className="app-profile-pic-container">
                            <p>{PreviewUser.User !== undefined && (PreviewUser.User.FirstName.charAt(0).replace(/\s/g, '') + "" + PreviewUser.User.LastName.charAt(0).replace(/\s/g, ''))}</p></div>}
                    <div className="app-profile-bio">
                        <h6>{PreviewUser.User !== undefined && (PreviewUser.User.FirstName + " " + PreviewUser.User.LastName)}</h6>
                        {PreviewUser.User !== undefined && PreviewUser.User.Bio != null && <p>{PreviewUser.User.Bio}</p>}
                        {PreviewUser.User !== undefined && ((Authenticated != PreviewUser.User.ID) && <MainBtn BtnStatus={CreatingFollower} BtnText={IsFollower ? "You are following " + PreviewUser.User.FirstName + " " + PreviewUser.User.LastName : "Follow " + PreviewUser.User.FirstName + " " + PreviewUser.User.LastName} btnOnclickHandler={followAuthor} />)}
                        <br />
                    </div>
                    <div className="app-profile-tabs">
                        <div className="card">
                            {PreviewUser.Followers !== undefined && <TabView>
                                <TabPanel header={"Followers (" + PreviewUser.Followers.length + ")"} leftIcon="pi pi-user mr-2">
                                    <div className="m-0">
                                        <FollowersRibbon viewProfile={viewProfile} List={PreviewUser.Followers} />
                                    </div>
                                </TabPanel>
                                <TabPanel header={"Articles (" + PreviewUser.Articles.length + ")"} rightIcon="pi pi-book ml-2">
                                    <div className="m-0">
                                        <ArticlesRibbon viewArticle={viewArticle} List={PreviewUser.Articles} />
                                    </div>
                                </TabPanel>
                                <TabPanel header={"Favorites (" + PreviewUser.Favourites.length + ")"} rightIcon="pi pi-heart ml-2">
                                    <div className="m-0">
                                        <FavouritesRibbon toggleArticle={toggleArticle} List={PreviewUser.Favourites} />
                                    </div>
                                </TabPanel>
                            </TabView>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterMenu setModalStatus={setModalStatus} setModalHeader={setModalHeader} />
    </>
}


