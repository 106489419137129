import React, { useState } from "react";

// components
import NavBar from "../shared/NavBar";
import Footer from "../shared/Footer";
import MainBtn from "../shared/MainBtn";
import DialogBox from "../shared/DialogBox";

// prime faces
import { Password } from "primereact/password";

// utils
import { User } from "../utils/interfaces/User";
import { MainServices } from "../utils/Services";
import { Results } from "../utils/interfaces/Results";

const ResetPasswordScreen: React.FC<{}> = (): JSX.Element => {
    const Services = new MainServices();

    const [OPassword, setOPassword] = useState("");
    const [RetypePassword, setRetypePassword] = useState("");

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("")
    const [DialogStatus, setDailogStatus] = useState(false);

    const resetPassword = async () => {
        const email: string = window.location.search.split("?email=")[1];

        if (OPassword == "" || RetypePassword == "") {
            setUpDialogValues(true, "Error", "Please fill in all required fields.");
        } else if (OPassword !== RetypePassword) {
            setUpDialogValues(true, "Error", "Your passwords do not match.")
        } else {
            setUpDialogValues(true, "Processing", "Please wait . . .");

            const params: User = {} as User;

            params.Email = email;
            params.Password = OPassword;

            const PasswordResults: Results = await Services.UpdatePassword(params);

            if (PasswordResults.Error) {
                setUpDialogValues(true, "Error", PasswordResults.ErrorDetail);
            } else {
                setUpDialogValues(true, "Success", "Transaction successful, proceed to login.");
            }
        }
    }

    const closeDialog = () => {
        setDailogStatus(false);
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDailogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    return <>
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />
        <NavBar />
        <section>
            <div className="reset-password body-container">
                <div className="body-content">
                    <h4>Reset your Password.</h4>

                    <div className="password-reset-form">
                        <label style={FormDefaultStyling.Labels}>Enter your new Password</label>
                        <div className="card flex justify-content-center border-0 m-1 sm:w-3 md:w-3 lg:w-3 w-full">
                            <Password value={OPassword} onChange={(e) => setOPassword(e.target.value)} toggleMask />
                        </div>
                        <label style={FormDefaultStyling.Labels}>Retype your new Password</label>
                        <div className="card flex justify-content-center border-0 m-1 sm:w-3 md:w-3 lg:w-3 w-full">
                            <Password value={RetypePassword} onChange={(e) => setRetypePassword(e.target.value)} toggleMask />
                        </div>
                        <div className="m-1 mt-2">
                            <MainBtn BtnText="Submit" btnOnclickHandler={resetPassword} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div id="reset-password-footer" style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <Footer />
        </div>
    </>
}

const FormDefaultStyling = {
    Labels: {
        fontSize: 13,
        margin: "0 0 0 5px"
    }
}

export default ResetPasswordScreen;