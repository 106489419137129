import React, { useState } from "react";

import MainBtn from "./MainBtn";

import FacebookIcon from "../assets/images/facebook.png";
import InstagramIcon from "../assets/images/instagram.png";

// components
import DialogBox from "./DialogBox";

// utils
import { FormValidation } from "../hooks/FormValidation";

import { MainServices } from "../utils/Services";
import { Results } from "../utils/interfaces/Results";

const Footer: React.FC<{}> = (): JSX.Element => {
    const Services = new MainServices();
    const { validateForm, validateEmail } = FormValidation();

    const [Email, setEmail] = useState("");
    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [DialogStatus, setDialogStatus] = useState(false);

    const CurrentDate = new Date().getFullYear();

    const subscriptionServices = async () => {
        const formFields = Array.from(document.querySelectorAll(".footer-subscribe input"));
        const isFormValid = validateForm(formFields);
        const isEmailValid = validateEmail(Email);
        const params: { Email: string } = {} as { Email: string };

        if (!isFormValid) {
            setUpDialogValues(true, "Error", "Please provide an email address.");
        } else if (!isEmailValid) {
            setUpDialogValues(true, "Error", "Please provide a valid email address.");
        } else {
            setUpDialogValues(true, "Processing", "Please wait...");

            params.Email = Email;

            const SubscriptionResults: Results = await Services.CreateSubscription(params);

            if (SubscriptionResults.Error) {
                setUpDialogValues(true, "Error", SubscriptionResults.ErrorDetail);
            } else {
                setEmail("");
                setUpDialogValues(true, "Success", "You have subscribed successfully. Thank you.");
            }
        }
    }

    const toggleHandleOutlet = (event: Event) => {
        event.preventDefault();
        setUpDialogValues(true, "Error", "This functionality isn't available currently, sorry try again later.");
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    return <>
        <footer>
            <DialogBox DialogMessage={DialogMessage} DialogHeader={DialogHeader} DialogStatus={DialogStatus} closeDialog={closeDialog} />
            <div className="footer-container" style={FooterDefaultStyling.footerContainer}>
                <div className="footer-content" style={FooterDefaultStyling.footerContent}>
                    <div className="footer-creds" style={{ display: "flex", justifyContent: "space-between" }}>
                        <p>&copy; {CurrentDate} Treasure Mkhonto | All Rights Reserved</p>
                        <p>Designed and Developed Inhouse</p>
                    </div>
                </div>
                <div className="footer-lower-content" style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                    margin: "0 auto"
                }}>
                    <div className="footer-content-first" style={FooterDefaultStyling.lowerContent}>
                        <h4>We are on these social platforms</h4>
                        <a href="https://www.facebook.com/profile.php?id=100082004255574" target="_blank"><img
                            src={FacebookIcon} alt="facebook" /></a>

                        <a href="" onClick={(event: any) => toggleHandleOutlet(event)}><img src={InstagramIcon}
                            alt="instagram" /></a>
                    </div>
                    <div className="footer-site-summary" style={FooterDefaultStyling.lowerContent}>
                        <h4>Check out these as well!!</h4>
                        <div className="site-summary-links" style={FooterDefaultStyling.summaryLinks}>
                            <a style={FooterDefaultStyling.summaryAnchors} href="https://scholar.treasuremkhonto.co.za">Scholar</a>
                            <a style={FooterDefaultStyling.summaryAnchors} href="/services">Web Development</a>
                            <a style={FooterDefaultStyling.summaryAnchors} href="/services">Android and iOS Apps Development</a>
                        </div>
                    </div>
                    <div className="footer-subscribe" style={FooterDefaultStyling.lowerContent}>
                        <h4>Subscribe for updates</h4>
                        <fieldset className="form-group">
                            <input type="email" onChange={(event: any) => setEmail((event.target as HTMLInputElement).value)} className="form-control" id="Email" value={Email} autoComplete="off" placeholder="Type your email here"
                                name="Email" />
                            <MainBtn btnOnclickHandler={subscriptionServices} BtnText={"Subscribe"} />
                        </fieldset>
                    </div>
                </div>
            </div>
        </footer >
    </>
}

const FooterDefaultStyling = {
    footerContent: {
        width: "90%",
        margin: "0 auto",
        fontSize: 13,
        padding: 10
    },
    footerContainer: {
        width: "100%",
        background: "#000000",
        overflow: "hidden",
        color: "#ffffff",
        borderTop: "1px solid #e6005c",

    },
    lowerContent: {
        width: "30%"
    },
    summaryLinks: {
        display: "grid",
    },
    summaryAnchors: {
        color: "#e6005c",
        padding: 5,
        fontSize: 14,
        textDecoration: "none"
    }
}

export default Footer;