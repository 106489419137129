import React from "react";

import { Article } from "../../../../../utils/interfaces/Article";

const ArticlesRibbon: React.FC<{ List: Article[], viewArticle: (article: Article) => void }> = ({ List, viewArticle }) => {
    const ImagePath: string = "https://nhlavutelo.co.za/images/";

    return <>
        {List.length > 0 && List.map((article: any, index: number) => {
            return <div onClick={() => viewArticle(article)} className="follow-card" key={index}>
                <div className="follower-content for-articles-ribbon">
                    <div className="follower-image" style={{ background: "url(" + ImagePath + article.ImageName + ")" }}></div>
                    <div className="follower-info">
                        <div className="follower-names">
                            <p>{article.TopicName}</p>
                            <p>{article.ArticleName.substring(0, 30)}</p>
                            <small>{article.ArticleDate}</small>
                        </div>
                    </div>
                </div>
            </div>
        })}
    </>
}

export default ArticlesRibbon;