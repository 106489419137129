export const TopicNames: string[] = [
    "All",
    "Education",
    "Politics",
    "Fashion",
    "Sports",
    "Men",
    "Women",
    "Sex",
    "Technology",
    "Money",
    "Current Affairs",
    "Relationships",
    "Food",
    "Jobs - Un|Employment",
    "Finances",
    "Religion",
    "Society",
    "Life",
    "Tourism",
    "Video Games",
    "Motivation"
];