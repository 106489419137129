import React from "react";
import Footer from "../shared/Footer";

// components
import NavBar from "../shared/NavBar";
import ContentCard from "../shared/ContentCard";

import MainBanner from "../assets/images/main_banner.jpg";
import TreasureImage from "../assets/images/treasure.jpeg";
import AppImageOne from "../assets/images/app_image_1.jpeg";
import AppImageTwo from "../assets/images/app_image_2.jpeg";
import AppImageThree from "../assets/images/app_image_3.jpeg";

import GooglePlayStore from "../assets/svg/google-play-button_1_.svg";

const HomeScreen: React.FC<{}> = (): JSX.Element => {
    const Contents: any[] = [{
        header: "Keynote Speaker", message: `Spanning over 7 years as a keynote speaker and in public speaking. Treasure Mkhonto continues to
    inspire many hearts and help people pursue God's purpose for their lives`},
    {
        header: "Author", message: `With over 4 years designing and writing magazines. Treasure Mkhonto is well known for his capacity to
        detail astouding facts in books - and in it - inspire many to greatness.`},
    {
        header: "Educator", message: `Educating people is what Treasure Mkhonto belives is his calling and Purpose.`
    },
    {
        header: "Entrepreneur", message: `Treasure Mkhonto - inspired by his ideas and desires to continually improve the quality of life, he utilizes his
        skills and knowledge to deliver excellent and timeless products and services.`}
    ];
    const VideoLinks: any[] = [
        { link: "https://www.youtube.com/embed/7NRDLAlsFio", title: "Money Farming Part 1" },
        { link: "https://www.youtube.com/embed/LFykFxqwjJ0", title: "Solutions to Unemployment" },
        { link: "https://www.youtube.com/embed/b2lGOhRFs3A", title: "Academic Excellence Part 1" },
        { link: "https://www.youtube.com/embed/9Mzt-tcUrog", title: "The Purpose for Education" }];

    const toggleGooglePlayStore = () => {
        window.open("https://play.google.com/store/apps/details?id=com.nhlavutelo.theblog", "_blank");
    }

    return <>
        <NavBar />
        <section>
            <div className="app-main-container">
                <div className="banner-container">
                    <div className="banner-content">
                        <img src={MainBanner} alt="student life poster" />
                        <div className="banner-inner-content">
                            <div className="inner-content-wrapper">
                                {Contents.map((item: any, index: number) => {
                                    return <ContentCard key={index} ImagePath="" Heading={item.header} Message={item.message} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="meet-trasure">
                    <div className="meet-treasure-container">
                        <div className="meet-treasure-content">
                            <div className="meet-left">
                                <img src={TreasureImage} alt="about treasure" />
                            </div>
                            <div className="meet-right">
                                <h4>Meet Treasure Mkhonto</h4>
                                <div className="treasure-quotes">
                                    <small>"the reason we have people who are failures, resonates from the fact that people have created their own agendas, they have not submitted and understood the plan, the purpose that God has for them."</small>
                                    <small>- Treasure Mkhonto</small>
                                </div>
                                <div className="treasure-more-info">
                                    <p>With a purpose mandate to help you understand and pursue your God given purpose, Treasure Mkhonto has embarked on a mission that seeks to help people understand life as God designed it
                                        and not as it is projected in society.
                                    </p>
                                    <p>
                                        Its important to realize that none of us actually decided that we would be born, into this world into whatever the time we were born -
                                        it was ultimately God's idea - understanding who we are, where we are and our future, is in essence having that relationship with the LORD Jesus Christ.
                                    </p>
                                    <p>There's a purpose to discover and pursure - Life as God created it, is actually perfect - <b>Ecclesiastes 3:14-17</b> - to learn more on that - join me, as I take you on a journey to discover God's purposes and plans for your life.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-app-advert">
                    <div className="mobile-app-container">
                        <div className="mobile-app-content">
                            <br />
                            <h4>TheBlog On Google Play Store</h4>
                            <p>The Blog is now available on Google Play Store and you can download the app right now</p>
                            <div className="for-app-images-content">
                                <div className="mobile-app-images">
                                    <img src={AppImageOne} alt="app image" />
                                    <img src={AppImageTwo} alt="app image" />
                                    <img src={AppImageThree} alt="app image" />
                                </div>
                                <div className="app-message">
                                    <p>Are you an author or an inspiring author? Do you want to make some good buck writing articles and or publishing your book digitally with us?
                                        TheBlog is offering exactly that - download the mobile app today and get started.
                                    </p>
                                </div>
                            </div>
                            <br />
                            <img onClick={toggleGooglePlayStore} style={{ cursor: "pointer" }} src={GooglePlayStore} alt="google play store" />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
                <div className="video-container">
                    <div className="video-content">
                        {VideoLinks.map((link: any, index: number) => {
                            return <div className="video-frame" key={index}>
                                <iframe width="560" height="315" src={link.link} title={link.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
}

export default HomeScreen;