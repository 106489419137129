import React, { useState, useEffect } from "react";

import { Results } from "../../../utils/interfaces/Results";

import { MainServices } from "../../../utils/Services";

import MainBtn from "../../../shared/MainBtn";
import DialogBox from "../../../shared/DialogBox";
import { Article } from "../../../utils/interfaces/Article";
import { User } from "../../../utils/interfaces/User";
import { INotification } from "../../../utils/interfaces/INotification";

const UnpublishedArticles: React.FC<{}> = (): JSX.Element => {
    const Services: MainServices = new MainServices();

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [Author, setAuthor] = useState<User>({} as User);

    const Months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [UnpublishedArticles, setUnpublishedArticles] = useState<Article[]>([]);
    const [SelectedArticle, setSelectedArticle] = useState<Article>({} as Article);
    const Today = new Date().getFullYear() + " " + Months[new Date().getMonth()] + " " + new Date().getDate() + " " + new Date().getHours() + "h" + new Date().getMinutes();

    const ImageAddress: string = "https://nhlavutelo.co.za/images/";

    const [DialogStatus, setDialogStatus] = useState(false);

    useEffect(() => {
        getUnpublishedArticles();

    }, []);

    const getUnpublishedArticles = async () => {
        const articlesResults: Results = await Services.GetUnplishedArticles();

        if (articlesResults.Error) {
            setUpDialogValues(true, "Error", articlesResults.ErrorDetail);
        } else {
            if (articlesResults.Results.length > 0) {
                setUnpublishedArticles(articlesResults.Results);
            }
        }
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const publishArticles = async () => {
        const articlesResults: Results = await Services.PublishArticle(SelectedArticle);

        setUpDialogValues(true, "Processing", "Please wait . . .");

        if (articlesResults.Error) {
            setUpDialogValues(true, "Error", articlesResults.ErrorDetail);
        } else {
            setUpDialogValues(true, "Success", "Article published successfully.");
            getUnpublishedArticles();
            createNotification(0);
        }
    }

    const createNotification = async (type: number) => {
        const params: INotification = {} as INotification;

        params.DateCreated = Today;
        params.UserID = SelectedArticle.UserID;
        params.Type = type;
        params.ArticleID = SelectedArticle.ID;
        params.AuthorID = SelectedArticle.UserID;
        params.ImageName = Author.ImageName;

        const createNotificationResults: Results = await Services.CreateNotification(params);

        if (createNotificationResults.Error) {
            setUpDialogValues(true, "Error", createNotificationResults.ErrorDetail);
        }
    }

    const getSelectedArticle = (event: any) => {
        const articleID = (event.target as HTMLSelectElement).value;


        if (articleID !== "") {
            const selectedArticle = UnpublishedArticles.find((article: Article) => {
                return (article.ID == Number(articleID));
            });

            if (selectedArticle) {
                getUser(selectedArticle.UserID);
                setSelectedArticle(selectedArticle);
            }

        }
    }

    const getUser = async (id: number) => {
        const user: User = {} as User;

        user.ID = id;

        const userResults: Results = await Services.GetUser(user);

        if (userResults.Error) {
            setUpDialogValues(true, "Error", userResults.ErrorDetail);
        } else {
            setAuthor(userResults.Results);
        }
    }

    return <>
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />
        <br />
        <h4>Unpublished Articles</h4>
        <div className="unpublished-articles" style={{ width: "70%" }}>
            <div className="article-container" style={{ pointerEvents: UnpublishedArticles.length > 0 !== undefined ? "all" : "none", display: "grid" }}>
                <div className="article-content" style={{ margin: 0 }}>
                    <br />
                    <fieldset className="form-group">
                        <select className="form-control" id="Articles" name="Articles" onChange={(event: any) => getSelectedArticle(event)}>
                            <optgroup>
                                <option value="">Select Article</option>
                                {UnpublishedArticles.map((article: Article, index: number) => {
                                    return <option key={index} value={article.ID}>{article.ArticleName}</option>
                                })}
                            </optgroup>
                        </select>
                    </fieldset>
                    {Author.ID !== undefined && <h6>Author: {Author.FirstName + " " + Author.LastName + "   | Email: " + Author.Email}</h6>}
                    <br />
                    <div className="article-image">
                        {SelectedArticle.ID != undefined &&
                            <img src={ImageAddress + SelectedArticle.ImageName} alt="article-image" />}
                    </div>
                    <br />
                    <h4>{SelectedArticle.ArticleName}</h4>
                    <div className="article-summary">
                        {SelectedArticle.ID == undefined ? <p style={{ margin: 10 }}>Loading Articles . . .</p> : <pre>{SelectedArticle.Article}</pre>}
                    </div>
                </div>
            </div>
        </div>
        <MainBtn btnOnclickHandler={publishArticles} BtnText="Publish Article" />
    </>
}

export default UnpublishedArticles;