export const useAccessModals = (setModalHeader: (header: string) => void, setModalStatus: (status: boolean) => void) => {

    const toggleModalStatus = () => {
        setModalStatus(true);
    }

    const toggleLoginModal = () => {
        setModalHeader("Login");
        toggleModalStatus();
    }

    const toggleRegisterModal = () => {
        setModalHeader("Register");
        toggleModalStatus();
    }

    const toggleRecoverModal = () => {
        setModalHeader("Recover");
        toggleModalStatus();
    }

    return { toggleLoginModal, toggleRecoverModal, toggleRegisterModal };

}