import React from "react";


const MainBtn: React.FC<{ btnOnclickHandler: any, BtnText: any, BtnStatus?: boolean, addClass?: string }> = ({ btnOnclickHandler, BtnText, BtnStatus, addClass }): JSX.Element => {
    return <>
        <div className={addClass !== "" ? addClass + " app-main-btn" : "app-main-btn"} onClick={btnOnclickHandler} style={{
            background: "#e6005c",
            padding: 10,
            margin: "10px 0 0 0",
            pointerEvents: BtnStatus ? "none" : "all",
            borderRadius: 5,
            cursor: "pointer",
            textAlign: "center",
            width: "30%",
            color: "#ffffff",
        }}>{BtnText}</div >
    </>
}

export default MainBtn;