import React, { useEffect } from "react";

import TheBlogIcon from "../../../assets/images/icon.png";

import { User } from "../../../utils/interfaces/User";
import { useDispatch, useSelector } from "react-redux";
import { useAccessModals } from "../hooks/useAccessModals";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated, setArticles, setAuthorFollowers, setBankingData, setFavourites, setNotifications, setUserDetails, setUserFollowers } from "../../../utils/redux/Actions/MainActions";

export const NavMenu: React.FC<{ setModalHeader: (name: string) => void, setModalStatus: (status: boolean) => void }> = ({ setModalHeader, setModalStatus }): JSX.Element => {
    const pathname = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const UserDetails: User = useSelector((state: any) => state.UserDetails);
    const Authenticated: number = useSelector((state: any) => state.Authenticated);

    const { toggleLoginModal, toggleRecoverModal, toggleRegisterModal } = useAccessModals(setModalHeader, setModalStatus);

    const removeSession = () => {
        navigate("/blog");
        dispatch(isAuthenticated(0));
        dispatch(setUserDetails({} as User));
        sessionStorage.clear();

    }

    useEffect(() => {
        let user: any = sessionStorage.getItem("user");
        if (user !== null) {
            user = JSON.parse(user);
            dispatch(setUserDetails(user.User));
            dispatch(setArticles(user.Articles));
            dispatch(setBankingData(user.Banking));
            dispatch(isAuthenticated(user.User.ID));
            dispatch(setFavourites(user.Favourites));
            dispatch(setUserFollowers(user.Followers));
            dispatch(setAuthorFollowers(user.Followers));
            dispatch(setNotifications(user.Notifications));

        }
    }, []);

    return <header>
        <div className="blog-header-container">
            <div className="blog-header-content">
                <div className="blog-header-inner-content">
                    <div className="app-blog-name">
                        <img src={TheBlogIcon} alt="app icon" />
                    </div>
                    <div className="app-nav">
                        {(Authenticated < 1 || Authenticated == undefined) && <ul>
                            <li><Link to="/blog">TheBlog</Link></li>
                            <li onClick={toggleLoginModal}>Sign In</li>
                            <li onClick={toggleRegisterModal}>Sign Up</li>
                            <li onClick={toggleRecoverModal}>Forgot Password?</li>
                        </ul>}
                        {Authenticated > 0 && <ul>
                            <li className={pathname.pathname == "/blog" ? "active-link" : ""}><Link to="/blog">TheBlog</Link></li>
                            <li className={pathname.pathname.indexOf("/profile") > -1 ? "active-link" : ""}><Link to={"/profile/" + UserDetails.FirstName + UserDetails.LastName}>{UserDetails.FirstName + " " + UserDetails.LastName}</Link></li>
                            <li className={pathname.pathname == "/banking" ? "active-link" : ""}><Link to="/banking">Banking</Link></li>
                            <li className={pathname.pathname == "/write" ? "active-link" : ""}><Link to="/write">Write</Link></li>
                            <li onClick={removeSession}>Sign Out</li>
                        </ul>}
                    </div>
                </div>
            </div>
        </div>
    </header>
}