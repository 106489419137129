import { MonthsList } from "../utils/MonthsList";

export const useDateObject = () => {


    const getNowDateAndTime = (args?: string): string => {
        const date = getNewDateObject(args ? args : "");

        const today = String(date.getFullYear() + " "
            + MonthsList[date.getMonth()] + " " +
            (date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()) + " "
            + (date.getHours() <= 9 ? "0" + date.getHours() : date.getHours()) + "h" + (date.getMinutes() <= 9 ? "0" : date.getMinutes()));

        return today;
    }

    const getNowDate = (args?: string): string => {
        const date = getNewDateObject(args ? args : "");

        const today = String(date.getFullYear() + " "
            + MonthsList[date.getMonth()] + " " +
            (date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()));

        return today;
    }

    const getNormalDate = (args?: string): string => {
        const date = getNewDateObject(args ? args : "");

        const day = getDay(date);
        const month = getMonth(date);

        const normalDate = date.getFullYear() + "/" + (month <= 9 ? "0" + month : month) + "/" + (day <= 9 ? "0" + day : day);

        return normalDate;

    }

    const getMonth = (date: Date): number => {
        const month = date.getMonth() + 1;

        return month;

    }

    const getDay = (date: Date): number => {
        const day = date.getDate();

        return day;

    }

    const getNewDateObject = (args: string): Date => {
        const date: Date = !args || args == "" ? new Date() : new Date(args);

        return date;

    }

    return { getNowDateAndTime, getNowDate, getNormalDate }
    
}