import React, { useState } from "react";

// components
import MainBtn from "./MainBtn";
import DialogBox from "./DialogBox";

// utilis
import { User } from "../utils/interfaces/User";
import { MainServices } from "../utils/Services";
import { Results } from "../utils/interfaces/Results";

// hooks
import { FormValidation } from "../hooks/FormValidation";

// redux 
import { useDispatch } from "react-redux";
import { isAuthenticated, setArticles, setAuthorFollowers, setBankingData, setFavourites, setNotifications, setUserDetails, setUserFollowers } from "../utils/redux/Actions/MainActions";
import { Followers } from "../utils/interfaces/Followers";

const LoginForm: React.FC<{ closeModal: any }> = ({ closeModal }): JSX.Element => {
    const dispatch = useDispatch();

    const { validateForm } = FormValidation();
    const Services: MainServices = new MainServices();

    const [Username, setUsername] = useState("");
    const [Password, setPassword] = useState("");

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [DialogStatus, setDialogStatus] = useState(false);

    const validateUser = async () => {
        const formFields = Array.from(document.querySelectorAll(".form-content input"));
        const isFormValid = validateForm(formFields);
        const params: User = {} as User;

        if (!isFormValid) {
            setUpDialogValues(true, "Error", "Please fill in all required fields.");
        } else {
            setUpDialogValues(true, "Processing", "Please wait...");

            params.Username = Username;
            params.Password = Password;

            const UserResults: Results = await Services.ValidateUser(params);

            if (UserResults.Error) {
                setUpDialogValues(true, "Error", UserResults.ErrorDetail);
            } else {
                dispatch(setUserDetails(UserResults.Results.User));
                dispatch(setArticles(UserResults.Results.Articles));
                dispatch(setBankingData(UserResults.Results.Banking));
                dispatch(isAuthenticated(UserResults.Results.User.ID));
                dispatch(setFavourites(UserResults.Results.Favourites));
                dispatch(setUserFollowers(UserResults.Results.Followers));
                dispatch(setAuthorFollowers(UserResults.Results.Followers));
                dispatch(setNotifications(UserResults.Results.Notifications));

                sessionStorage.setItem("user", JSON.stringify(UserResults.Results));

                getUserFollowers(UserResults.Results.User.ID);

                setUsername("");
                setPassword("");
                closeDialog();
                closeModal();
            }
        }
    }

    const getUserFollowers = async (id: number) => {
        const follower: Followers = {} as Followers;

        follower.AuthorID = id;

        const FollowersResults: Results = await Services.GetUserFollowers(follower);

        if (FollowersResults.Error) {
            setUpDialogValues(true, "Error", FollowersResults.ErrorDetail);
        } else {
            dispatch(setUserFollowers(FollowersResults.Results));
        }
    }


    const closeDialog = () => {
        setDialogStatus(false);
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    return <>
        <DialogBox DialogMessage={DialogMessage} DialogHeader={DialogHeader} DialogStatus={DialogStatus} closeDialog={closeDialog} />
        <div className="login-form">
            <div className="form-content">
                <label style={FormDefaultStyling.Labels}>Enter your Username</label>
                <fieldset className="form-group">
                    <input type="text" placeholder="Username" id="Username" name="Username" className="form-control" value={Username} onChange={(event: any) => setUsername(event.target.value)} />
                </fieldset>
                <label style={FormDefaultStyling.Labels}>Enter your Password</label>
                <fieldset className="form-group">
                    <input type="password" placeholder="Password" id="Password" name="Password" className="form-control" value={Password} onChange={(event: any) => setPassword(event.target.value)} />
                </fieldset>
                <MainBtn BtnText="Login" btnOnclickHandler={validateUser} />
            </div>
        </div>
    </>
}

const FormDefaultStyling = {
    Labels: {
        fontSize: 13,
        margin: "0 0 0 5px"
    }
}

export default LoginForm;