import { User } from "../../interfaces/User";
import { Comment } from "../../interfaces/Comment";
import { IBanking } from "../../interfaces/IBanking";
import { Article } from "../../interfaces/Article";
import { Views } from "../../interfaces/Views";
import { Followers } from "../../interfaces/Followers";
import { Likes } from "../../interfaces/Likes";
import { IFavourite } from "../../interfaces/IFavourite";
import { INotification } from "../../interfaces/INotification";

export const isAuthenticated = (id: number) => {
    return {
        type: "ISAUTH",
        payload: id
    }
}

export const setUserDetails = (user: User) => {
    return {
        type: "USERDETAILS",
        payload: user
    }
}

export const setPreviewUser = (user: any) => {
    return {
        type: "PREVIEWUSER",
        payload: user
    }
}

export const setCommentsData = (comments: Comment[]) => {
    return {
        type: "COMMENTSDATA",
        payload: comments
    }
}

export const setBankingData = (banking: IBanking) => {
    return {
        type: "BANKING",
        payload: banking
    }
}

export const setArticles = (articles: Article[]) => {
    return {
        type: "ARTICLES",
        payload: articles
    }
}

export const setCurrentArticle = (article: Article) => {
    return {
        type: "ARTICLE",
        payload: article
    }
}

export const setViews = (views: Views[]) => {
    return {
        type: "VIEWS",
        payload: views
    }
}

export const setFavourites = (favourites: IFavourite[]) => {
    return {
        type: "FAVOURITES",
        payload: favourites
    }
}

export const setNotifications = (notifications: INotification[]) => {
    return {
        type: "NOTIFICATIONS",
        payload: notifications
    }
}

export const setUserFollowers = (followers: Followers[]) => {
    return {
        type: "USERFOLLOWERS",
        payload: followers
    }
}

export const setAuthorFollowers = (followers: Followers[]) => {
    return {
        type: "AUTHORFOLLOWERS",
        payload: followers
    }
}

export const setLikes = (likes: Likes[]) => {
    return {
        type: "LIKES",
        payload: likes
    }
}