import { EnvironmentVariables } from "../utils/Environment";

export const useFetch = () => {
    const url: string = EnvironmentVariables.URLs.MainUrl;

    const runFetchPost = async (params: any, endPoint: string) => {
        return fetch(url + endPoint, {
            method: "POST",
            headers: {
                "Accept": "/",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json());
    }

    const runFetchGet = async (endPoint: string) => {

        return fetch(url + endPoint, {
            method: "GET",
            headers: {
                "Accept": "/",
                "Content-Type": "application/json"
            },
        })
            .then((response: any) => response.json());
    }

    return { runFetchPost, runFetchGet };
}