import React, { useState, useEffect } from "react";

import { NavMenu } from "../../shared/NavMenu";
import MainBtn from "../../../../shared/MainBtn";
import DialogBox from "../../../../shared/DialogBox";
import { FooterMenu } from "../../shared/FooterMenu";

import { useDispatch, useSelector } from "react-redux";

import { Article } from "../../../../utils/interfaces/Article";

import { TopicNames } from "../../../../Topics";

import BannerTwo from "../../../../assets/images/main_banner_2.jpeg";
// primefaces 
import { InputTextarea } from "primereact/inputtextarea";

import { EnvironmentVariables } from "../../../../utils/Environment";

import { Link, useNavigate } from "react-router-dom";

import { useWriteData } from "./hooks/useWriteData";
import { useDateObject } from "../../../../hooks/useDateObject";
import { setArticles } from "../../../../utils/redux/Actions/MainActions";

export const Write: React.FC<{}> = (): JSX.Element => {
    const ImagesEndPoint = EnvironmentVariables.URLs.AppUrl + "/images/";

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { getNormalDate } = useDateObject();
    const { addArticle, updateArticle, buildArticle } = useWriteData();

    // boolean states
    const [IsCreatingArticle, setIsCreatingArticle] = useState(false);

    const [ModalStatus, setModalStatus] = useState(false);
    const [DialogStatus, setDialogStatus] = useState(false);

    const [ModalHeader, setModalHeader] = useState("");
    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");


    const [ArticleName, setArticleName] = useState("");
    const [ArticleTopic, setArticleTopic] = useState("");
    const [ArticleContent, setArticleContent] = useState("");

    const UserArticles: Article[] = useSelector((state: any) => state.Articles);
    const Authenticated: number = useSelector((state: any) => state.Authenticated);

    const [WordsCount, setWordsCount] = useState(300);
    const [CharactersCount, setCharactersCount] = useState(1500);

    const [ArticleImage, setArticleImage] = useState<{ type: string, base64Image: string }>({ type: "", base64Image: "" } as { type: string, base64Image: string });

    const [ArticleImageName, setArticleImageName] = useState("");
    const [Filter, setFilter] = useState<Article>({ Article: "", ArticleDate: "", ArticleName: "", ImageName: "" } as Article);

    useEffect(() => {
        if (Authenticated < 1)
            navigate("/blog");
    }, []);

    const getSelectArticle = (event: CustomEvent<any>) => {
        const id = Number((event.target as HTMLSelectElement).value);

        const selectedArticle = UserArticles.find((item: Article) => {
            return (item.ID == id);
        });

        if (selectedArticle) {
            setFilter(selectedArticle);
            setArticleContent(selectedArticle.Article);
            setArticleName(selectedArticle.ArticleName);
            setArticleTopic(selectedArticle.TopicName);
            setArticleImageName(selectedArticle.ImageName);
        } else {
            setFilter({ ImageName: "" } as Article);
            setArticleName("");
            setArticleTopic("");
            setArticleContent("");
            setArticleImageName("");
        }
    }

    const compareDates = (currentDate: Date, previousDate: Date) => {
        const difference = currentDate.getTime() - previousDate.getTime();
        const daysDifference = Math.ceil(difference / (1000 * 3600 * 24));

        return daysDifference
    };

    const createArticle = async () => {
        let isImageAvailable = true;
        let isEligibleToPublish = true;
        let ArticleResults: any;

        let daysDifference = 0;
        let lastArticle = {} as Article;

        if (UserArticles.length > 0) {
            lastArticle = UserArticles[UserArticles.length - 1];

            const daysDifference = compareDates(new Date(), new Date(lastArticle.ArticleDate));

            if (daysDifference < 7)
                isEligibleToPublish = false;
        }

        if (!isEligibleToPublish) {
            setUpDialogValues(true, "Error", "You need to wait one week before you can plublish another article. You will be able to publish your next article in " + (7 - daysDifference) + " days");
        } else if (ArticleName.length > 50) {
            setUpDialogValues(true, "Error", "Your Article Name should not exceed 50 characters. You currently have " + ArticleName.length);
        } else if (WordsCount < 0) {
            setUpDialogValues(true, "Error", "You have exceeded the allowed number of word.");
        } else if (ArticleContent == "") {
            setUpDialogValues(true, "Error", "Please write an article of at least 150 words.");
        } else if (ArticleContent.split(" ").length < 150) {
            setUpDialogValues(true, "Error", "An article should have at least 150 words.");
        } else if (ArticleName == "") {
            setUpDialogValues(true, "Error", "Please provide an article name.");
        } else if (ArticleTopic == "") {
            setUpDialogValues(true, "Error", "Please select a topic.");
        } else {
            setIsCreatingArticle(true);

            const params: Article = buildArticle(Authenticated, ArticleContent, ArticleTopic, ArticleName, ArticleImage.base64Image, getNormalDate());

            if (Filter.ID > 0) {
                if (Filter.ImageName == "")
                    isImageAvailable = false;
            } else {
                if (ArticleImage.base64Image == "")
                    isImageAvailable = false;
            }

            if (!isImageAvailable) {
                setIsCreatingArticle(false);
                setUpDialogValues(true, "Error", "Please provide an image.");
            } else {

                if (Filter.ID > 0) {
                    params.Published = 0;
                    params.ArticleDate = Filter.ArticleDate;

                    ArticleResults = await updateArticle(params);
                } else {
                    ArticleResults = await addArticle(params);
                }

                if (ArticleResults.Error) {
                    setUpDialogValues(true, "Error", ArticleResults.ErrorDetail);

                } else {
                    setUpDialogValues(true, "Success", Filter.ID ? "Article updated successfully, and is now in review. You will be notified once published." : "Article created successfully and sent for review, you will be notified once published/rejected.");

                    if (!Filter.ID) {
                        const list: Article[] = UserArticles;

                        params.ID = ArticleResults.Results;

                        list.push(params);
                        dispatch(setArticles(list));

                    }
                }

                setIsCreatingArticle(false);

            }
        }
    }

    const toggleFileDir = () => {
        (document.getElementById("Image") as HTMLInputElement).click();
    }

    const updateArticleContent = (item: string) => {
        const text: string = item;
        const textCount: string[] = text.split(" ");

        setArticleContent(item);
        setWordsCount(300 - textCount.length);
    }

    const openFileDir = (event: any) => {
        let fileReader;
        let base64Image = "";

        const fileObject = event.currentTarget.files[0];

        if (fileObject.size > 1000000) {
            setUpDialogValues(true, "Error", "The Image file should not be more than 1mb in size - your current image is " + fileObject.size / 1000000 + "mb in size");
        } else {
            if (event.currentTarget.accept.indexOf(fileObject.type) < 0) {
                setUpDialogValues(true, "Error", "File formart wrong, allowed formarts are: png, jpeg, jpg, bmp");
            } else {
                fileReader = new FileReader();
                fileReader.onload = function (e) {
                    base64Image = (e.target as any).result;
                    setArticleImage({ type: fileObject.type, base64Image: base64Image })

                };

                fileReader.readAsDataURL(fileObject);

            }
        }
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    return <>
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />

        <NavMenu setModalHeader={setModalHeader} setModalStatus={setModalStatus} />

        <section>
            <div className="writing-container">
                <div className="writing-content">
                    <div className="write-article-stats">
                        <div>
                            <span><b>Max Words</b>: {WordsCount} Words</span>
                        </div>
                        <div>
                            <span><b>Max Chararacters</b>: {CharactersCount}</span>
                        </div>
                    </div>
                    <br />
                    <div className="article-selection">
                        <label>Select Article</label>
                        <fieldset className="form-group">
                            <select id="Filter" name="Filter" onChange={(event: any) => getSelectArticle(event)} className="form-control">
                                <optgroup>
                                    <option value="">Select Article</option>
                                    <option value="">Clear Selected Article</option>
                                    {UserArticles.map((article: Article, index: number) => {
                                        return <option key={index} value={article.ID}>{article.ArticleName}</option>
                                    })}
                                </optgroup>
                            </select>
                        </fieldset>
                    </div>
                    <div className="article-selection">
                        <label>Select Article Topic</label>
                        <fieldset className="form-group">
                            <select id="ArticleTopic" name="ArticleTopic" onChange={(event: any) => setArticleTopic(event.target.value)} value={ArticleTopic} className="form-control">
                                <optgroup>
                                    <option value="">Select Article Topic</option>
                                    {TopicNames.map((item: string, index: number) => {
                                        return <option key={index} value={item}>{item}</option>
                                    })}
                                </optgroup>
                            </select>
                        </fieldset>
                    </div>
                    <div className="writing-article-form">
                        <span onClick={toggleFileDir}>Upload Article Image</span>
                        <fieldset className="form-group" style={{ display: "none" }}>
                            <input type="file" accept="image/x-png,image/jpeg,image/jpg,image/bmp" id="Image" className="form-control" name="Image" onChange={(event: any) => openFileDir(event)} />
                        </fieldset>
                        <fieldset className="form-group">
                            <input type="text" id="ArticleName" value={ArticleName} placeholder="Article Name" className="form-control" name="ArticleName" onChange={(event: any) => setArticleName(event.target.value)} />
                        </fieldset>
                        <small style={{ margin: 10, color: "red", display: "block" }}>If you skip a line, make sure its 2 lines and not just 1 -
                            this will present the article much better.
                            Click this link <Link to="https://treasuremkhonto.co.za/article-view/1" target="_blank">Test Article</Link> to preview how articles should be presented. </small>
                        <div className="card flex justify-content-center border-0 sm:w-3 md:w-3 lg:w-3 w-full h-4">
                            <InputTextarea autoResize placeholder="Write your article here - or paste it here . . ." value={ArticleContent} onChange={(e: any) => updateArticleContent(e.target.value)} rows={1} cols={30} />
                        </div>
                        <MainBtn BtnStatus={IsCreatingArticle} BtnText="Submit" btnOnclickHandler={createArticle} />
                    </div>
                    <div className="article-image">
                        {Filter.ImageName !== "" && ArticleImage.base64Image == "" ?
                            <img src={Filter.ImageName !== "" ? ImagesEndPoint + Filter.ImageName : BannerTwo} alt="article-image" /> : ArticleImage.base64Image == "" ?
                                <img src={BannerTwo} alt="article-image" /> : ArticleImage.base64Image == "" && Filter.ImageName == "" ?
                                    <img src={BannerTwo} alt="article-image" /> : <img src={ArticleImage.base64Image} alt="article-image" />}
                    </div>
                    <br />
                    <div className="writing-article-preview">
                        <h4>{ArticleName}</h4>
                        <pre>{ArticleContent}</pre>
                    </div>
                </div>
            </div>
        </section>
        <FooterMenu setModalStatus={setModalStatus} setModalHeader={setModalHeader} />
    </>
}