import { useFetch } from "../../../../../hooks/useFetch";
import { EnvironmentVariables } from "../../../../../utils/Environment";
import { IBanking } from "../../../../../utils/interfaces/IBanking";
import { Results } from "../../../../../utils/interfaces/Results";
import { Withdrawals } from "../../../../../utils/interfaces/Withdrawals";

export const useBankingDetails = () => {
    const EndPoints = EnvironmentVariables.EndPoints;

    const { runFetchPost } = useFetch();

    const addBankingDetails = async (Banking: IBanking) => {
        const results: Results = await runFetchPost(Banking, EndPoints.Banking.SetBankingDetails);

        return results;
    }

    const updateBankingDetails = async (Banking: IBanking) => {
        const results: Results = await runFetchPost(Banking, EndPoints.Banking.UpdateBankingDetails);

        return results;
    }

    const addWithdrawal = async (Withdrawal: Withdrawals) => {
        const results: Results = await runFetchPost(Withdrawal, EndPoints.Withdrawals.CreateWithdrawal);

        return results;
    }

    const buildBanking = (bankName: string, accountNumber: string, userID: number, balance: number, cardNumber: string, cellphoneNumber: string, id: number) => {
        return {
            UserID: userID,
            BankName: bankName,
            AccountNumber: accountNumber,
            ID: id,
            Balance: balance,
            CardNumber: cardNumber,
            CellphoneNumber: cellphoneNumber
        };
    }

    return { addBankingDetails, buildBanking, addWithdrawal, updateBankingDetails };
}