import React from "react";

import NavBar from "../shared/NavBar";
import Footer from "../shared/Footer";
import ContentCard from "../shared/ContentCard";

import TreasureImage from "../assets/images/treasure.jpeg";
import AboutBanner from "../assets/images/about_banner.jpg";

const AboutUsScreen: React.FC<{}> = (): JSX.Element => {
    const Contents: any[] = [{
        header: "Endlani Zitha", message: `You inspired many people, myself included. I started teaching the pursuit of purpose because of you. Many are walking in God's direction because of those teachings.`
    },
    {
        header: "Wisdom Maluleke", message: `You've always been an inspiration to me personally. You enriched me with accurate knowledge of God's word and also paved a path for me career wise.`
    },
    {
        header: "Mixo Ngobeni", message: `Treasure Mkhonto has played an important role in my life as a brother. One of the beautiful things he did was to introduce me to The Pursuit of Purpose, and that has always been the topic of our discussion. Understanding The Pursuit of Purpose has opened my eyes and helped me discover who I am, why I exist, and what I should do.`
    }
    ];

    const moveSliderItem = (dir: string) => {
        alert(dir);
    }

    return <>
        <NavBar />
        <section>
            <div className="about-us-container">
                <div className="about-us-banner">
                    <img src={AboutBanner} alt="about banner" />
                    <div className="about-banner-summary">
                        <div className="about-summary">
                            <h4>Meet Treasure Mkhonto</h4>
                            <small>aka - the purpose and education expert</small>
                            <p>A <b>minister of the gospel of Jesus Christ</b>, qualified <b>developer</b>, a <b>husband</b> and <b>father</b> - a <b>keynote speaker and author.</b></p>
                            <p>Let me take you on a journey of pursuing God's purpose for your life.</p>
                        </div>
                    </div>
                </div>
                <div className="about-us-content">
                    <div className="about-us-content-overlay">
                        <div className="content-wrapper">
                            <div className="about-image">
                                <img src={TreasureImage} alt="treasure" />
                            </div>
                            <div className="about-content">
                                <p>Precisely in 2012, the Lord Jesus would enlighten me on what my life would be about. Specificically told me who to listen to, the books to read
                                    and the things to think on. This would in turn birth a very strong desire in my heart with regards to what to do to both teach and depict God's purpose
                                    and plans for mankind. <br /><br />
                                    The following year, the Lord would tell me to learn both graphics design and web development - because He said, '<i>you will need to document these things that I teach you and make them accessible to everyone.</i>'
                                </p>
                                <p>Under the tutelage of the man of God pastor Chris, I would establish what was then known as BrightMinds - with sponsorship worth just over 100k in rands from the man of God, I would
                                    embark on a national journey to help people discover their God given purpose, so that they can live their lives as God intended.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-what-others-think">
                    <div className="about-what-others-think-content">
                        <h4>What others say about <b>Treasure Mkhonto</b></h4>
                        <div className="content-slider">
                            <div className="slider-for-content">
                                <div className="slides">
                                    {Contents.map((item: any, index: number) => {
                                        return <ContentCard key={index} ImagePath="" Heading={item.header} Message={item.message} />
                                    })}
                                </div>
                                {/* <div className="slider-controls">
                                    <MainBtn BtnText="Previous" btnOnclickHandler={() => moveSliderItem("Prev")} />
                                    <MainBtn BtnText="Next" btnOnclickHandler={() => moveSliderItem("Next")} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
}

export default AboutUsScreen;