import { useFetch } from "../../../../../hooks/useFetch"

import { Likes } from "../../../../../utils/interfaces/Likes";
import { Results } from "../../../../../utils/interfaces/Results";
import { ICommentLike } from "../../../../../utils/interfaces/ICommentLike";

import { EnvironmentVariables } from "../../../../../utils/Environment";

export const useLikesData = () => {
    const EndPoints = EnvironmentVariables.EndPoints;

    const { runFetchPost } = useFetch();

    const addArticleLike = async (like: any) => {
        const results: Results = await runFetchPost(like, EndPoints.Like.SetLike);

        return results;
    }

    const addCommentLike = async (SelectedComment: ICommentLike) => {
        const results: Results = await runFetchPost(SelectedComment, EndPoints.CommentsLikes.CreateCommentLike);

        return results;
    }

    const buildLike = (aid: number, cid: number, id: number, date: string, type: number) => {
        return (cid > 0) ? {
            "ArticleID": aid,
            "CommentID": cid,
            "UserID": id,
            "DateCreated": date,
            "CommentLike": type == 1 ? 1 : 0,
            "CommentDislike": type == 2 ? 1 : 0,
        } : {
            "ArticleID": aid,
            "CommentID": cid,
            "UserID": id,
            "DateCreated": date,
            "ArticleLike": type == 1 ? 1 : 0,
            "Dislike": type == 2 ? 1 : 0,
        };
    }

    return { addArticleLike, buildLike, addCommentLike }
}