export const FormValidation = () => {
    const validateForm = (fields: any[]) => {
        const inValidFields = fields.find((item: any) => {
            return (item.value == "");
        });

        if (inValidFields)
            return false;
        else
            return true;
    }

    const validateEmail = (email: string) => {
        const isEmailValid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));

        return isEmailValid;
    }

    return { validateForm, validateEmail };

}