import React from "react";
import { User } from "../utils/interfaces/User";


const UserRibbon: React.FC<{ List: User[], viewProfile: (user: User) => void }> = ({ List, viewProfile }) => {
    const ImagePath: string = "https://nhlavutelo.co.za/images/";

    return <>
        {List.length > 0 && List.map((user: User, index: number) => {
            return <div onClick={() => viewProfile(user)} className="follow-card" key={index}>
                <div className="follower-content">
                    {user.ImageName == null ? <div className="follower-image">
                        <p>
                            {user.FirstName.charAt(0).replace(/\s/g, '') + "" +user.LastName.charAt(0).replace(/\s/g, '')}
                        </p>
                    </div> : <div className="follower-image" style={{ background: "url(" + ImagePath + user.ImageName + ")" }}></div>}
                    <div className="follower-info">
                        <div className="follower-names">
                            <p>{user.FirstName.substring(0, 9) + " " + user.LastName.substring(0, 9)}</p>
                            <p>{user.Gender}</p>
                        </div>
                    </div>
                </div>
            </div>
        })}
    </>
}

export default UserRibbon;