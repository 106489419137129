import React from "react";

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";


const ContentShare: React.FC<{ url: any }> = ({ url }): JSX.Element => {
    const SiteUrl: string = "https://treasuremkhonto.co.za";

    return <>
        <FacebookShareButton url={SiteUrl + "/" + url}>
            <FacebookIcon></FacebookIcon>
        </FacebookShareButton>
        <WhatsappShareButton url={SiteUrl + "/" + url}>
            <WhatsappIcon></WhatsappIcon>
        </WhatsappShareButton>
        <TwitterShareButton url={SiteUrl + "/" + url}>
            <TwitterIcon></TwitterIcon>
        </TwitterShareButton>
        <LinkedinShareButton url={SiteUrl + "/" + url}>
            <LinkedinIcon></LinkedinIcon>
        </LinkedinShareButton>
        <EmailShareButton url={SiteUrl + "/" + url}>
            <EmailIcon></EmailIcon>
        </EmailShareButton>
    </>
}

export default ContentShare;