import React, { useState } from "react";

// prime faces 
import { Password } from "primereact/password";

// components
import MainBtn from "./MainBtn";
import DialogBox from "./DialogBox";

// utilis
import { useDispatch } from "react-redux";
import { User } from "../utils/interfaces/User";
import { MainServices } from "../utils/Services";
import { Results } from "../utils/interfaces/Results";
import { isAuthenticated, setAuthorFollowers, setBankingData, setFavourites, setNotifications, setUserDetails, setUserFollowers } from "../utils/redux/Actions/MainActions";

// hooks
import { FormValidation } from "../hooks/FormValidation";
import { IReferral } from "../utils/interfaces/IReferral";

const RegisterForm: React.FC<{ closeModal: () => void, Ref?: User }> = ({ closeModal, Ref }): JSX.Element => {
    const dispatch = useDispatch();

    const Today = new Date().getFullYear() + "/" + ((new Date().getMonth() + 1) < 9 ? "0" + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)) + "/" + new Date().getDate();

    const Services: MainServices = new MainServices();
    const { validateForm, validateEmail } = FormValidation();

    const [Email, setEmail] = useState("");
    const [LastName, setLastName] = useState("");
    const [Username, setUsername] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [UserPassword, setUserPassword] = useState("");

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [DialogStatus, setDialogStatus] = useState(false);

    const createUser = async () => {
        const formFields = Array.from(document.querySelectorAll(".form-content input"));
        const isFormValid = validateForm(formFields);
        const isEmailValid = validateEmail(Email);
        const params: User = {} as User;

        if (!isFormValid) {
            setUpDialogValues(true, "Error", "Please fill in all required fields.");
        } else if (!isEmailValid) {
            setUpDialogValues(true, "Error", "Please provide a valid email address.");
        } else {
            setUpDialogValues(true, "Processing", "Please wait...");

            params.Email = Email;
            params.LastName = LastName;
            params.Username = Username;
            params.FirstName = FirstName;
            params.Password = UserPassword;

            const UserResults: Results = await Services.CreateUser(params);

            if (UserResults.Error) {
                setUpDialogValues(true, "Error", UserResults.ErrorDetail);
            } else {
                setEmail("");
                setUsername("");
                setLastName("");
                setFirstName("");
                setUserPassword("");
                closeModal();
                closeDialog();
                dispatch(setUserDetails(UserResults.Results.User));
                dispatch(isAuthenticated(UserResults.Results.User.ID));

                dispatch(setUserDetails(UserResults.Results.User));
                dispatch(isAuthenticated(UserResults.Results.User.ID));
                dispatch(setBankingData(UserResults.Results.Banking));
                dispatch(setFavourites(UserResults.Results.Favourites));
                dispatch(setUserFollowers(UserResults.Results.Followers));
                dispatch(setAuthorFollowers(UserResults.Results.Followers));
                dispatch(setNotifications(UserResults.Results.Notifications));

                sessionStorage.setItem("user", JSON.stringify(UserResults.Results));

                if (Ref !== undefined && Ref.ID > 0)
                    subscribeReferral(UserResults.Results);
            }
        }
    }

    const subscribeReferral = async (user: User) => {
        const params: IReferral = {} as IReferral;

        params.UserID = Ref?.ID!;
        params.NewUserID = user.ID;
        params.DateRegistered = Today;

        const referralResults: Results = await Services.CreateReferral(params);
        console.log(referralResults);
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    return <>
        <DialogBox DialogMessage={DialogMessage} DialogHeader={DialogHeader} DialogStatus={DialogStatus} closeDialog={closeDialog} />
        <div className="login-form">
            <div className="form-content">
                <label style={FormDefaultStyling.Labels}>Enter your Username</label>
                <fieldset className="form-group">
                    <input type="text" placeholder="Username" id="Username" name="Username" className="form-control" value={Username} onChange={(event: any) => setUsername(event.target.value)} />
                </fieldset>
                <label style={FormDefaultStyling.Labels}>Enter your First Name</label>
                <fieldset className="form-group">
                    <input type="text" placeholder="First Name" id="FirstName" name="FirstName" className="form-control" value={FirstName} onChange={(event: any) => setFirstName(event.target.value)} />
                </fieldset>
                <label style={FormDefaultStyling.Labels}>Enter your Last Name</label>
                <fieldset className="form-group">
                    <input type="text" placeholder="Last Name" id="LastName" name="LastName" className="form-control" value={LastName} onChange={(event: any) => setLastName(event.target.value)} />
                </fieldset>
                <label style={FormDefaultStyling.Labels}>Enter your Email Address</label>
                <fieldset className="form-group">
                    <input type="email" placeholder="Email" id="Email" name="Email" className="form-control" value={Email} onChange={(event: any) => setEmail(event.target.value)} />
                </fieldset>
                <label style={FormDefaultStyling.Labels}>Enter your Password</label>
                <div className="card flex justify-content-center border-0 m-1 sm:w-3 md:w-3 lg:w-3 w-full h-4">
                    <Password value={UserPassword} onChange={(event: any) => setUserPassword(event.target.value)} toggleMask />
                </div>
                <MainBtn BtnText="Register" btnOnclickHandler={createUser} />
            </div>
        </div>
    </>
}

const FormDefaultStyling = {
    Labels: {
        fontSize: 13,
        margin: "0 0 0 5px"
    }
}

export default RegisterForm;