import { combineReducers } from "redux";

import {
    Authenticated,
    UserDetails,
    Banking,
    Articles,
    CurrentArticle,
    Likes,
    Favourites,
    Notifications,
    Views,
    PreviewUser,
    CommentsData,
    UserFollowers,
    AuthorFollowers
} from "./Reducers";

const allReducers = combineReducers({
    Authenticated,
    UserDetails,
    Banking,
    CurrentArticle,
    Articles,
    Likes,
    Favourites,
    Notifications,
    Views,
    PreviewUser,
    CommentsData,
    UserFollowers,
    AuthorFollowers
});

export default allReducers;