import React, { useState, useEffect } from "react";

import NavBar from "../shared/NavBar";
import Footer from "../shared/Footer";
import MainBtn from "../shared/MainBtn";
import ModalBox from "../shared/ModalBox";

import { Link, useNavigate, useLocation } from "react-router-dom";


import MainBanner from "../assets/images/main_banner_2.jpeg";
import { User } from "../utils/interfaces/User";
import { Results } from "../utils/interfaces/Results";
import { MainServices } from "../utils/Services";
import DialogBox from "../shared/DialogBox";

const ReferralScreen: React.FC<{}> = (): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();

    const Services = new MainServices();

    const [Reference, setReference] = useState("");

    const [ModalHeader, setModalHeader] = useState("");

    const [ModalStatus, setModalStatus] = useState(false);

    const [UserData, setUserData] = useState<User>({} as User);

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [DialogStatus, setDialogStatus] = useState(false);

    useEffect(() => {
        const email: any = location.search.split("=")[1];
        setReference(email);

        if (email)
            getUserDetails(email);
    }, []);

    const getUserDetails = async (email: string) => {
        const user: User = {} as User;

        user.Email = email;

        const userResults: Results = await Services.GetUser(user);

        if (userResults.Error) {
            setUpDialogValues(true, "Error", userResults.ErrorDetail);
        } else {
            setUserData(userResults.Results);
        }
    }

    const signUpUser = () => {
        setModalStatus(true);
        setModalHeader("Register");
    }

    const closeModal = () => {
        setModalStatus(false);
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    return <>
        <NavBar />
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />
        <ModalBox Header={ModalHeader} Ref={UserData} closeModal={closeModal} Status={ModalStatus} />
        <section>
            <div className="contact-us-banner">
                <img src={MainBanner} alt="main banner" />
            </div>
            <div className="contact-us-container">
                <div className="contact-us-content">
                    {UserData.ID !== undefined ? <>
                        <h4>You were referred to this platform by - {UserData.FirstName + " " + UserData.LastName}</h4>
                        <p>To learn more about Write and Earn please click on this link <Link to="/write-earn">About Write and Earn</Link></p>
                        <p>To start the onboarding process, please 'Sign Up' button below</p>
                        <MainBtn BtnText="Sign Up" btnOnclickHandler={signUpUser} />
                    </> : <p>Oops, something went wrong . . .</p>}
                </div>
            </div>
        </section>
        <Footer />
    </>
}

export default ReferralScreen;