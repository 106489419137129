import React, { useState } from "react";

import { MainServices } from "../../../utils/Services";

import DialogBox from "../../../shared/DialogBox";

import { User } from "../../../utils/interfaces/User";
import { Article } from "../../../utils/interfaces/Article";

import Chart from "chart.js/auto";
import MainBtn from "../../../shared/MainBtn";

const ChartOverview: React.FC<{ Articles: number, Users: number, Authors: number, Likes: number, Views: number, Comments: number, Followers: number }> = (
    { Users, Authors, Likes, Views, Comments, Followers, Articles }
): JSX.Element => {
    let chart: any;

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [Author, setAuthor] = useState<User>({} as User);

    const [UnpublishedArticles, setUnpublishedArticles] = useState<Article[]>([]);
    const [SelectedArticle, setSelectedArticle] = useState<Article>({} as Article);

    const ImageAddress: string = "https://nhlavutelo.co.za/images/";

    const [DialogStatus, setDialogStatus] = useState(false);


    const startChart = () => {
        if (chart)
            chart.destroy();

        const ctx = (document.getElementById("myChart") as HTMLCanvasElement);

        chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: ['Users', 'Authors', 'Articles', 'Followers', 'Views', 'Likes', 'Comments'],
                datasets: [{
                    label: "User Stats",
                    data: [Users, Authors, Articles, Followers, Views, Likes, Comments],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });
    }


    const closeDialog = () => {
        setDialogStatus(false);
    }

    return <>
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />
        <br />
        <div className="charts-container">
            <h4>Chart Overview</h4>
            <MainBtn btnOnclickHandler={startChart} BtnText="Toggle Chart" />
            <div className="charts-container">
                <canvas id="myChart"></canvas>
            </div>
        </div>
    </>
}

export default ChartOverview;