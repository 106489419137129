import React, { useState } from "react";

import NavBar from "../shared/NavBar";
import Footer from "../shared/Footer";
import MainBtn from "../shared/MainBtn";
import DialogBox from "../shared/DialogBox";

import { FormValidation } from "../hooks/FormValidation";

import MainBanner from "../assets/images/main_banner_2.jpeg";

import { MainServices } from "../utils/Services";
import { Results } from "../utils/interfaces/Results";
import { ContactForm } from "../utils/interfaces/ContactForm";

const ContactUsScreen: React.FC<{}> = (): JSX.Element => {
    const Services = new MainServices();
    const { validateForm, validateEmail } = FormValidation();

    const [Names, setNames] = useState("");
    const [Email, setEmail] = useState("");
    const [Subject, setSubject] = useState("");
    const [Message, setMessage] = useState("");

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [DialogStatus, setDialogStatus] = useState(false);

    const submitRequest = async () => {
        const formFields = Array.from(document.querySelectorAll(".contact-us-form input, .contact-us-form textarea"));
        const isFormValid = validateForm(formFields);
        const isEmailValid = validateEmail(Email);
        const params: ContactForm = {} as ContactForm;

        if (!isFormValid) {
            setUpDialogValues(true, "Error", "Please fill in all required fields");
            return;
        }

        if (!isEmailValid) {
            setUpDialogValues(true, "Error", "Please provide a valid email address");
            return;
        }

        if (isEmailValid && isFormValid) {
            setUpDialogValues(true, "Processing", "Please wait...");

            params.Names = Names;
            params.Email = Email;
            params.Subject = Subject;
            params.Message = Message;

            const ContactUsResults: Results = await Services.ContactUs(params);

            if (ContactUsResults.Error) {
                setUpDialogValues(true, "Error", ContactUsResults.ErrorDetail);
            } else {
                setNames("");
                setEmail("");
                setSubject("");
                setMessage("");
                setUpDialogValues(true, "Success", "Thank you for reaching out, will get back to you shortly.");
            }

        }
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    return <>
        <NavBar />
        <DialogBox DialogMessage={DialogMessage} DialogHeader={DialogHeader} DialogStatus={DialogStatus} closeDialog={closeDialog} />
        <section>
            <div className="contact-us-banner">
                <img src={MainBanner} alt="main banner" />
            </div>
            <div className="contact-us-container">
                <div className="contact-us-content">
                    <h4>I'd love to hear from you</h4>

                    <div className="contact-us-form">
                        <fieldset className="form-group">
                            <input type="text" placeholder="What's this about ?" onChange={(event: any) => setSubject((event.target as HTMLInputElement).value)} value={Subject} id="Subject" name="Subject" className="form-control" />
                        </fieldset>
                        <fieldset className="form-group">
                            <input type="text" placeholder="Your Names - like Full Names - first and last name" onChange={(event: any) => setNames((event.target as HTMLInputElement).value)} value={Names} id="Names" name="Names" className="form-control" />
                        </fieldset>
                        <fieldset className="form-group">
                            <input type="email" placeholder="Your valid email address, so I can get back to you" onChange={(event: any) => setEmail((event.target as HTMLInputElement).value)} value={Email} id="Email" name="Email" className="form-control" />
                        </fieldset>
                        <fieldset className="form-group">
                            <textarea placeholder="Your message here . . ." onChange={(event: any) => setMessage((event.target as HTMLInputElement).value)} value={Message} id="Message" name="Message" className="form-control"></textarea>
                        </fieldset>
                        <MainBtn btnOnclickHandler={submitRequest} BtnText={"Submit"} />
                    </div>
                    <div className="additional-contacts">
                        <h4>Or use these</h4>
                        <p><b>phone</b>: +2767 958 1135 or +2761 4125 819</p>
                        <p><b>email</b>: <a href="mailto:hello@treasuremkhonto.co.za">hello@treasuremkhonto.co.za</a></p>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
}

export default ContactUsScreen;