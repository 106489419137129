import React from "react";
import { User } from "../../../../../utils/interfaces/User";
import { INotification } from "../../../../../utils/interfaces/INotification";

const NotificationsRibbon: React.FC<{ List: INotification[], removeNotification?: any, viewProfile: (user: User) => void }> = ({ List, removeNotification, viewProfile }) => {
    const ImagePath: string = "https://nhlavutelo.co.za/images/";

    return <>
        {List.length > 0 && List.map((notifcation: any, index: number) => {
            let typeString = "";
            const names = notifcation.UserNames.split(" ");

            switch (notifcation.Type) {
                case 1:
                    typeString = "New like on '" + notifcation.ArticleName + "'";
                    break;
                case 2:
                    typeString = "New comment on '" + notifcation.ArticleName + "'";
                    break;
                case 3:
                    typeString = "New article published '" + notifcation.ArticleName + "'";
                    break;
                case 4:
                    typeString = "New comment like on '" + notifcation.ArticleName + "'";
                    break;
                case 5:
                    typeString = "New comment reply on '" + notifcation.ArticleName + "'";
                    break;
                case 6:
                    typeString = "New follower '" + notifcation.UserNames + "'";
                    break;
                default:
                    typeString = "New mention on '" + notifcation.ArticleName + "'";
                    break;
            }
            return <div onClick={() => viewProfile(notifcation)} className="follow-card" key={index}>
                <div className="follower-content">
                    {notifcation.ImageName == null ? <div className="follower-image">
                        <p>
                            {names[0].charAt(0).replace(/\s/g, '') + "" + names[1].charAt(0).replace(/\s/g, '')}
                        </p>
                    </div> : <div className="follower-image" style={{ background: "url(" + ImagePath + notifcation.ImageName + ")" }}></div>}
                    <div className="follower-info">
                        <div className="follower-names">
                            <p>{notifcation.DateCreated}</p>
                            <p>{typeString.substring(0, 25)}</p>
                        </div>
                    </div>
                    <div onClick={() => removeNotification(notifcation)} style={{ color: "red", cursor: "pointer" }} className="follower-options"><i className="bi bi-x-circle-fill"></i></div>
                </div>
            </div>
        })}
    </>
}

export default NotificationsRibbon;