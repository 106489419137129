import React, { useState, useEffect } from "react";

import NavBar from "../shared/NavBar";
import Footer from "../shared/Footer";
import DialogBox from "../shared/DialogBox";

import MainBtn from "../shared/MainBtn";

import { MainServices } from "../utils/Services";
import { Results } from "../utils/interfaces/Results";
import { Downloads } from "../utils/interfaces/Downloads";


const DownloadsScreen: React.FC<{}> = (): JSX.Element => {
    const Services: MainServices = new MainServices();

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [DialogStatus, setDialogStatus] = useState(false);

    const [Downloads, setDownloads] = useState<Downloads[]>([]);

    useEffect(() => {
        getDownloads();
    }, []);

    const getDownloads = async () => {
        const GetDownloadsResults: Results = await Services.GetDownloads();

        if (GetDownloadsResults.Error)
            setUpDialogValues(true, "Error", GetDownloadsResults.ErrorDetail);
        else
            setDownloads(GetDownloadsResults.Results);

    }

    const downloadMagazine = async (download: Downloads) => {
        const params: Downloads = download;
        const tempList: Downloads[] = Downloads;

        setDownloads([]);

        params.Count = download.Count + 1;
        setUpDialogValues(true, "Loading", "Please wait . . .");

        const SetDownloadsResults: Results = await Services.SetDownloads(params);

        if (SetDownloadsResults.Error) {
            setUpDialogValues(true, "Error", SetDownloadsResults.ErrorDetail);
        } else {
            tempList.forEach((item: Downloads) => {
                if (item.ID == download.ID)
                    item.Count = params.Count;
            });

            setDownloads(tempList);

            closeDialog();
            window.open(download.Link);

        }
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    return <>
        <NavBar />
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />
        <section>
            <div className="downloads-container">
                <div className="downloads-overlay">
                    <div className="downloads-content">
                        <h4>Downloads</h4>
                        <div className="downloads-list">
                            {Downloads.map((download: Downloads, index: number) => {
                                return <div key={index} className="downloads-images-container">
                                    <h6>{download.BookName}</h6>
                                    <div className="downloads-image">
                                        <img src={download.ImageName} alt={download.BookName} />
                                    </div>
                                    <MainBtn BtnText={"Download (" + download.Count + ")"} btnOnclickHandler={() => downloadMagazine(download)} />
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
}

export default DownloadsScreen;