import React, { useEffect } from "react";

import { User } from "../../../utils/interfaces/User";
import { useDispatch, useSelector } from "react-redux";
import { useAccessModals } from "../hooks/useAccessModals";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated, setArticles, setAuthorFollowers, setBankingData, setFavourites, setNotifications, setUserDetails, setUserFollowers } from "../../../utils/redux/Actions/MainActions";

import { EnvironmentVariables } from "../../../utils/Environment";


export const FooterMenu: React.FC<{
    setModalHeader: (name: string) => void,
    setModalStatus: (status: boolean) => void
}> = ({ setModalHeader,
    setModalStatus }): JSX.Element => {
        const ImagePath = EnvironmentVariables.URLs.AppUrl + "/images/";

        const pathname = useLocation();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const { toggleLoginModal, toggleRecoverModal, toggleRegisterModal } = useAccessModals(setModalHeader, setModalStatus);

        const UserDetails: User = useSelector((state: any) => state.UserDetails);
        const Authenticated: number = useSelector((state: any) => state.Authenticated);

        useEffect(() => {
            let user: any = sessionStorage.getItem("user");
            if (user !== null) {
                user = JSON.parse(user);
                dispatch(setUserDetails(user.User));
                dispatch(setArticles(user.Articles));
                dispatch(setBankingData(user.Banking));
                dispatch(isAuthenticated(user.User.ID));
                dispatch(setFavourites(user.Favourites));
                dispatch(setUserFollowers(user.Followers));
                dispatch(setAuthorFollowers(user.Followers));
                dispatch(setNotifications(user.Notifications));

            }
        }, []);

        const removeSession = () => {
            navigate("/blog");
            dispatch(isAuthenticated(0));
            dispatch(setUserDetails({} as User));
            sessionStorage.clear();

        }

        const toggleModal = (type: number) => {
            switch (type) {
                case 0:
                    navigate("/blog");
                    break;
                case 1:
                    toggleLoginModal();
                    break;
                case 2:
                    toggleRegisterModal();
                    break;
                default:
                    toggleRecoverModal();
                    break;
            }
        }


        return <footer>
            <div className="footer-menu">
                <div className="footer-menu-content">
                    {Authenticated > 0 ?
                        <div className="default-app-menu">
                            <span onClick={() => toggleModal(0)} className={pathname.pathname == "/blog" ? "active-link" : ""}><Link to="/blog"><span className="bi bi-house-heart-fill"></span></Link></span>
                            <span onClick={() => toggleModal(1)} className={pathname.pathname.indexOf("/profile") > -1 ?
                                "active-link" : ""}>
                                <Link to={"/profile/" + UserDetails.FirstName + UserDetails.LastName}>
                                    {UserDetails.ImageName !== null ? <div className="user-profile-pic" style={{ background: "url(" + ImagePath + UserDetails.ImageName + ")" }}>
                                    </div> : <div className="user-profile-pic">
                                        <span>{UserDetails.FirstName.charAt(0).replace(/\s/g, '') + "" + UserDetails.LastName.charAt(0).replace(/\s/g, '')}</span>
                                    </div>}
                                </Link>
                            </span>
                            <span onClick={() => toggleModal(2)} className={pathname.pathname == "/banking" ? "active-link" : ""}><Link to="/banking"><span className="bi bi-cash-coin"></span></Link></span>
                            <span onClick={() => toggleModal(2)} className={pathname.pathname == "/write" ? "active-link" : ""}><Link to="/write"><span className="bi bi-journal-text"></span></Link></span>
                            <span onClick={removeSession} className="bi bi-escape"></span>
                        </div>
                        :
                        <div className="is-logged-in-menu">
                            <span onClick={() => toggleModal(0)} className="bi bi-house-heart-fill"></span>
                            <span onClick={() => toggleModal(1)} className="bi bi-shield-lock-fill"></span>
                            <span onClick={() => toggleModal(2)} className="bi bi-person-vcard-fill"></span>
                            <span onClick={() => toggleModal(3)} className="bi bi-key-fill"></span>
                        </div>
                    }
                </div>
            </div>
        </footer >
    }