import React from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const DialogBox: React.FC<{
    DialogMessage: string,
    DialogHeader: string,
    DialogStatus: boolean,
    closeDialog: any,
    confirmDecision?: any
    isConditional?: boolean
}> = ({ DialogMessage, DialogHeader, DialogStatus, closeDialog, isConditional, confirmDecision }): JSX.Element => {
    const footerContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => confirmDecision(false)} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => confirmDecision(true)} autoFocus />
        </div>
    );

    return <>
        <Dialog header={DialogHeader} visible={DialogStatus} style={{ width: '50vw' }} onHide={closeDialog} footer={isConditional && footerContent}>
            <p className="m-2 ml-0 mr-0">
                {DialogMessage}
            </p>
        </Dialog>
    </>
}

export default DialogBox;