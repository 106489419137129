import React, { useState } from "react";

import { Dialog } from "primereact/dialog";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import RecoverPassword from "./ResetPassword";
import { User } from "../utils/interfaces/User";

const ModalBox: React.FC<{
    Header: string,
    Status: boolean,
    closeModal: () => void,
    Ref?: User
}> = ({ Header, Status, closeModal, Ref }): JSX.Element => {

    return <>
        <Dialog header={Header} visible={Status} style={{ width: '30vw' }} onHide={closeModal}>
            {Header == "Login" ? <LoginForm closeModal={closeModal} /> : ""}
            {Header == "Register" ? <RegisterForm Ref={Ref} closeModal={closeModal} /> : ""}
            {Header == "Recover" ? <RecoverPassword closeModal={closeModal} /> : ""}
        </Dialog>
    </>
}

export default ModalBox;