import React, { useState, useEffect } from "react";

// primefaces 
import { InputTextarea } from "primereact/inputtextarea";

// components 
import MainBtn from "../../../shared/MainBtn";

import { MentionPopUp } from "./MentionsPopUp";

import { Followers } from "../../../utils/interfaces/Followers";

const CommentForm: React.FC<{
    createComment: any,
    Comment: string,
    setComment: any,
    FollowersList: Followers[],
    BtnTextUpdate?: boolean
}> = ({ createComment,
    Comment,
    FollowersList,
    setComment,
    BtnTextUpdate }): JSX.Element => {

        const [Mentioned, setMentioned] = useState<any[]>([]);

        const [Users, setUsers] = useState<Followers[]>(FollowersList);
        const [ShowPopUp, setPopUp] = useState(false);

        useEffect(() => {
            setMentioned([]);
        }, [Comment == ""]);

        const handleMentions = (e: CustomEvent<Event>) => {
            const mention: string = (e.target as HTMLTextAreaElement).value;

            (mention.indexOf("@") > -1) ? setPopUp(true) : setPopUp(false);
        }

        const handleFieldChange = async (e: CustomEvent<Event>) => {
            const comment = (e.target as HTMLTextAreaElement).value;

            const ifThreeChars = comment.slice(comment.indexOf("@") + 1);

            setComment(comment);

            if (ifThreeChars.length >= 3 && comment.indexOf("@") > -1 && FollowersList.length > 0) {
                const followers = FollowersList.filter((follower: any) => {
                    return (follower.UserNames.toLowerCase().match(ifThreeChars.toLowerCase()));
                });

                if (followers.length > 0) {
                    setUsers(followers);
                } else {
                    setUsers([]);
                }
            } else {
                setUsers([]);
            }
        }

        const setMention = async (follower: any) => {
            let comment = Comment;

            const ifThreeChars = comment.slice(comment.indexOf("@") + 1);

            comment = comment.replace(ifThreeChars, "");

            comment = comment + follower.UserNames;

            comment = comment.replace("@", "");

            setMentioned([...Mentioned, follower]);

            setComment(comment);
            setUsers([]);

            setPopUp(false);
        }

        return <>
            {(ShowPopUp && Users.length > 0) && <MentionPopUp setMention={setMention} FollowersList={Users} />}
            <div className="card flex justify-content-center border-0 sm:w-3 md:w-3 lg:w-3 w-full h-4">
                <InputTextarea autoResize placeholder="Type your comment here . . ." value={Comment} onKeyUp={(e: any) => handleMentions(e)} onChange={(e: any) => handleFieldChange(e)} rows={1} cols={30} />
            </div>
            <MainBtn BtnStatus={BtnTextUpdate} BtnText={BtnTextUpdate ? "Processing ..." : "Submit"} btnOnclickHandler={() => createComment(Mentioned)} />
        </>
    }

export default CommentForm;