import React, { useState, useEffect } from "react";

// primefaces 
import { InputTextarea } from "primereact/inputtextarea";
import { Followers } from "../../../../../utils/interfaces/Followers";
import { MentionPopUp } from "../../../components/MentionsPopUp";





const CommentReplyForm: React.FC<{
    CommentID: number,
    ClearText: boolean,
    FollowersList: Followers[],
    createCommentReply: (CommentReply: string, CommentID: number, Mentioned: any[]) => void,
}> = ({ createCommentReply, CommentID, ClearText, FollowersList }): JSX.Element => {
    const [Users, setUsers] = useState<any[]>([]);
    const [Mentioned, setMentioned] = useState<any[]>([]);

    const [ShowPopUp, setPopUp] = useState(false);

    const [CommentReply, setCommentReply] = useState("");

    useEffect(() => {
        setCommentReply("");
        setMentioned([]);
    }, [ClearText]);

    const handleMentions = (e: CustomEvent<Event>) => {
        const mention: string = (e.target as HTMLTextAreaElement).value;

        (mention.indexOf("@") > -1) ? setPopUp(true) : setPopUp(false);
    }

    const handleFieldChange = async (e: CustomEvent<Event>) => {

        const comment = (e.target as HTMLTextAreaElement).value;

        const ifThreeChars = comment.slice(comment.indexOf("@") + 1);

        setCommentReply(comment);

        if (ifThreeChars.length >= 3 && comment.indexOf("@") > -1 && FollowersList.length > 0) {
            const followers = FollowersList.filter((follower: any) => {
                return (follower.UserNames.toLowerCase().match(ifThreeChars.toLocaleLowerCase()));
            });

            if (followers.length > 0) {
                setUsers(followers);
            } else {
                setUsers([]);
            }
        } else {
            setUsers([]);
        }
    }

    const setMention = async (follower: any) => {
        let comment = CommentReply;

        const ifThreeChars = comment.slice(comment.indexOf("@") + 1);

        comment = comment.replace(ifThreeChars, "");

        comment = comment + follower.UserNames;

        comment = comment.replace("@", "");

        setMentioned([...Mentioned, follower]);

        setCommentReply(comment);
        setUsers([]);

        setPopUp(false);
    }


    return <>
        {(ShowPopUp && Users.length > 0) && <MentionPopUp setMention={setMention} FollowersList={Users} />}
        <div className="flex border-0 sm:w-3 md:w-3 lg:w-3 w-full h-4 comment-reply-box" style={{ display: "flex" }}>
            <InputTextarea autoResize placeholder="Type your reply here . . ."
                style={{ width: "90%" }} value={CommentReply}
                onKeyUp={(e: any) => handleMentions(e)}
                onChange={(e: any) => handleFieldChange(e)}
                rows={1} cols={30} />
            <span onClick={() => createCommentReply(CommentReply, CommentID, Mentioned)} style={{ width: "10%", cursor: 'pointer', margin: "7px 0 0 5px", color: "#e6005c", display: "block" }} className="bi bi-send-check-fill"></span>
        </div>
    </>
}

export default CommentReplyForm;