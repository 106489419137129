import React from "react";
import { Followers } from "../../../utils/interfaces/Followers";

export const MentionPopUp: React.FC<{ setMention: (follower: Followers) => void, FollowersList: Followers[] }> = ({ setMention, FollowersList }): JSX.Element => {
    return <>
        <div className="mentions-pop-up">
            <div className="mentions-popup-content">
                <ul>
                    {FollowersList.map((follower: any, index: number) => {
                        return <li key={index} onClick={() => setMention(follower)}>{follower.UserNames}</li>
                    })}
                </ul>
            </div>
        </div>
    </>
}