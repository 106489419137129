import React, { useState, useEffect } from "react";

import { NavMenu } from "../../shared/NavMenu";
import DialogBox from "../../../../shared/DialogBox";

// prime faces 
import { Password } from "primereact/password";
import { TabView, TabPanel } from "primereact/tabview";
import { InputTextarea } from "primereact/inputtextarea";

import { User } from "../../../../utils/interfaces/User";
import { Article } from "../../../../utils/interfaces/Article";
import { Results } from "../../../../utils/interfaces/Results";
import { Followers } from "../../../../utils/interfaces/Followers";
import { IFavourite } from "../../../../utils/interfaces/IFavourite";
import { INotification } from "../../../../utils/interfaces/INotification";

import { useDispatch, useSelector } from "react-redux";

import BannerTwo from "../../../../assets/images/main_banner_2.jpeg";

import FavouritesRibbon from "./components/Favourites";
import ArticlesRibbon from "./components/ArticlesRibbon";
import FollowersRibbon from "./components/FollowersRibbon";
import NotificationsRibbon from "./components/NotificationRibbon";

import { useNavigate } from "react-router-dom";

import { EnvironmentVariables } from "../../../../utils/Environment";

import MainBtn from "../../../../shared/MainBtn";
import { ImageView } from "../../shared/ImageView";
import { FooterMenu } from "../../shared/FooterMenu";
import ContentShare from "../../../../shared/ContentShare";

import { useFetch } from "../../../../hooks/useFetch";
import { useProfileData } from "./hooks/useProfileData";
import { FormValidation } from "../../../../hooks/FormValidation";

import { setCurrentArticle, setFavourites, setNotifications, setPreviewUser, setUserDetails, setUserFollowers } from "../../../../utils/redux/Actions/MainActions";

export const Profile: React.FC<{}> = (): JSX.Element => {
    const EndPoints = EnvironmentVariables.EndPoints;
    const ImagePath = EnvironmentVariables.URLs.AppUrl + "/images/";

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { runFetchPost } = useFetch();
    const { validateForm, validateEmail } = FormValidation();
    const { addDetails, addImage, addPassword, buildDetails } = useProfileData();

    const [ModalStatus, setModalStatus] = useState(false);
    const [DialogStatus, setDialogStatus] = useState(false);

    const [IsChangingImage, setIsChangingImage] = useState(false);

    const [ReferralLink, setReferralLink] = useState("");

    const [ModalHeader, setModalHeader] = useState("");
    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [UserPassword, setUserPassword] = useState("");
    const [UserRetypePassword, setUserRetypePassword] = useState("");

    const [SelectedImage, setSelectedImage] = useState("");

    const UserDetails: User = useSelector((state: any) => state.UserDetails);
    const ArticlesList: Article[] = useSelector((state: any) => state.Articles);
    const Authenticated: number = useSelector((state: any) => state.Authenticated);
    const FavouritesList: IFavourite[] = useSelector((state: any) => state.Favourites);
    const AuthorFollowers: Followers[] = useSelector((state: any) => state.AuthorFollowers);
    const Notifications: INotification[] = useSelector((state: any) => state.Notifications);

    const [UserFollowersList, setUserFollowersList] = useState<Followers[]>(AuthorFollowers);
    const [UserFavouritesList, setUserFavouritesList] = useState<IFavourite[]>(FavouritesList);
    const [UserNotificationsList, setUserNotificationsList] = useState<INotification[]>(Notifications);

    const [Bio, setBio] = useState(UserDetails.Bio == null ? "" : UserDetails.Bio);
    const [Email, setEmail] = useState(UserDetails.Email == null ? "" : UserDetails.Email);
    const [Gender, setGender] = useState(UserDetails.Gender == null ? "" : UserDetails.Gender);
    const [Address, setAddress] = useState(UserDetails.Address == null ? "" : UserDetails.Address);
    const [LastName, setLastName] = useState(UserDetails.LastName == null ? "" : UserDetails.LastName);
    const [Username, setUsername] = useState(UserDetails.Username == null ? "" : UserDetails.Username);
    const [FirstName, setFirstName] = useState(UserDetails.FirstName == null ? "" : UserDetails.FirstName);
    const [DateOfBirth, setDateOfBirth] = useState(UserDetails.DateOfBirth == null ? "" : UserDetails.DateOfBirth);
    const [CellphoneNumber, setCellphoneNumber] = useState(UserDetails.CellphoneNumber == null ? "" : UserDetails.CellphoneNumber);

    const [UserImage, setUserImage] = useState<{ type: string, base64Image: string }>({ type: "", base64Image: "" } as { type: string, base64Image: string });

    useEffect(() => {
        if (Authenticated < 1)
            navigate("/blog");
    }, []);

    useEffect(() => {
        getUserData();
    }, [UserDetails.ID]);

    const getUserData = async () => {
        const userResults: Results = await runFetchPost({ ID: UserDetails.ID }, EndPoints.User.GetUser);

        if (!userResults.Error) {
            dispatch(setFavourites(userResults.Results.Favourites));
            dispatch(setUserFollowers(userResults.Results.Followers));
            dispatch(setNotifications(userResults.Results.Notifications));

            setUserFollowersList(userResults.Results.Followers);
            setUserFavouritesList(userResults.Results.Favourites);
            setUserNotificationsList(userResults.Results.Notifications);
        }
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const removeFollower = async (follower: Followers) => {
        const params: any = {};

        params.ID = follower.ID;

        const followResults: Results = await runFetchPost(params, EndPoints.Follower.DeleteFollower);

        if (followResults.Error) {
            setUpDialogValues(true, "Error", followResults.ErrorDetail);
        } else {
            getUserData();
        }
    }

    const removeNotification = async (notification: INotification) => {
        const params: INotification = notification;

        params.IsDeleted = 1;
        params.AuthorID = notification.AuthorID;
        params.ID = notification.ID;

        const notifcationResults: Results = await runFetchPost(params, EndPoints.Notifications.UpdateNotification);

        if (notifcationResults.Error) {
            setUpDialogValues(true, "Error", notifcationResults.ErrorDetail);
        } else {
            getUserData();
        }
    }

    const viewArticle = (article: Article) => {
        dispatch(setCurrentArticle({} as Article));
        navigate("/article-view/" + article.ID);
    }

    const viewProfile = async (user: any) => {
        const results: Results = await runFetchPost({ ID: user.UserID }, EndPoints.User.GetUser);

        if (results.Error) {
            setUpDialogValues(true, "Error", results.ErrorDetail);
        } else {
            dispatch(setPreviewUser(results.Results));
            navigate("/preview/" + results.Results.User.FirstName + " " + results.Results.User.LastName);
        }
    }

    const toggleArticle = (favourite: IFavourite) => {
        navigate("/article-view/" + favourite.ArticleID);
    }

    const removeFavourite = async (favourite: IFavourite) => {
        const favouritesResults: Results = await runFetchPost(favourite, EndPoints.Favourites.RemoveFavourite);

        if (favouritesResults.Error) {
            setUpDialogValues(true, "Error", favouritesResults.ErrorDetail)
        } else {
           getUserData();
        }
    }

    const updateImage = async () => {
        if (UserImage.base64Image !== "" && UserImage.type !== "") {
            setUpDialogValues(true, "Processing", "Please wait...");

            const results: Results = await addImage({ ID: Authenticated, ImageName: UserImage.base64Image });

            setIsChangingImage(false);

            if (results.Error) {
                setUpDialogValues(true, "Error", results.ErrorDetail);
            } else {
                setUpDialogValues(true, "Success", "Image updated successfully.");
            }
        } else {
            setUpDialogValues(true, "Error", "Please select an image");
        }
    }

    const openFileDir = (event: any) => {
        let fileReader;
        let base64Image = "";

        const fileObject = event.currentTarget.files[0];

        if (event.currentTarget.accept.indexOf(fileObject.type) < 0) {
            setUpDialogValues(true, "Error", "File formart wrong, allowed formarts are: png, jpeg, jpg, bmp");
        } else {
            fileReader = new FileReader();
            fileReader.onload = function (e) {
                base64Image = (e.target as any).result;
                setUserImage({ type: fileObject.type, base64Image: base64Image })
                setIsChangingImage(true);
            };

            fileReader.readAsDataURL(fileObject);

        }
    }

    const handleBioChanges = (text: string) => {
        if (text.length > 100) {
            text = text.substring(0, 100);
        }

        setBio(text);

    }

    const updateUserDetails = async () => {
        const formFields = Array.from(document.querySelectorAll(".user-form input"));
        const isFormValid = validateForm(formFields);
        const isEmailValid = validateEmail(Email);

        if (!isFormValid) {
            setUpDialogValues(true, "Error", "Please fill in all required fields.");
        } else if (!isEmailValid) {
            setUpDialogValues(true, "Error", "Please provide a valid email address.");
        } else {
            setUpDialogValues(true, "Processing", "Please wait...");

            const params = buildDetails(Authenticated, FirstName, LastName, Gender, DateOfBirth, Email, CellphoneNumber, Bio, Username, Address);

            const results: Results = await addDetails(params);

            if (results.Error) {
                setUpDialogValues(true, "Error", results.ErrorDetail);
            } else {
                dispatch(setUserDetails(params));
                setUpDialogValues(true, "Success", "Details updated successfully.");
            }
        }
    }

    const toggleFileDir = () => {
        (document.getElementById("Image") as HTMLInputElement).click();
    }

    const handleLinkGeneration = () => {
        const url = "https://treasuremkhonto.co.za/referral/" + UserDetails.Email;
        setReferralLink(url);
    }

    const updatePassword = async () => {
        const formFields = Array.from(document.querySelectorAll(".password-form input"));
        const isFormValid = validateForm(formFields);

        if (!isFormValid) {
            setUpDialogValues(true, "Error", "Please fill in all required fields.");
        } else if (UserPassword !== UserRetypePassword) {
            setUpDialogValues(true, "Error", "Your Password do not match.");
        } else {
            setUpDialogValues(true, "Processing", "Please wait...");

            const results: Results = await addPassword({ ID: Authenticated, Password: UserPassword });

            if (results.Error) {
                setUpDialogValues(true, "Error", results.ErrorDetail);
            } else {
                setUpDialogValues(true, "Success", "Password updated successfully.");
            }
        }
    }

    const toggleFullView = () => {
        setSelectedImage(ImagePath + UserDetails.ImageName);

    }

    const closeViewer = () => {
        setSelectedImage("");
    }

    return <>
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />

        <NavMenu setModalHeader={setModalHeader} setModalStatus={setModalStatus} />

        <section>
            {SelectedImage !== "" && <ImageView closeViewer={closeViewer} imagePath={SelectedImage} />}
            <div className="app-profile-container">
                <div className="app-profile-content">
                    <div className="app-profile-banner">
                        <img src={BannerTwo} alt="banner" />
                    </div>
                    {UserDetails.ImageName !== null && UserImage.base64Image == "" ?
                        <div className="app-profile-pic-container" onClick={toggleFullView} style={{ background: "url(" + ImagePath + UserDetails.ImageName + ")" }}></div>
                        : UserImage.base64Image ?
                            <div className="app-profile-pic-container" onClick={toggleFullView} style={{ background: "url(" + UserImage.base64Image + ")" }}></div> :
                            <div className="app-profile-pic-container">
                                <p>{UserDetails.ImageName !== undefined && UserDetails.FirstName.charAt(0).replace(/\s/g, '') + "" + UserDetails.LastName.charAt(0).replace(/\s/g, '')}</p></div>}
                    <i onClick={toggleFileDir} className="bi bi-images"></i>
                    <div className="app-profile-bio">
                        <p>{UserDetails.Bio}</p>
                    </div>
                    <div className="app-profile-tabs">
                        <div className="card">
                            <TabView>
                                <TabPanel header="Personal Details" leftIcon="pi pi-user mr-2">
                                    <div className="m-0">
                                        <div className="user-image-container">
                                            <fieldset className="form-group" style={{ display: "none" }}>
                                                <input type="file" accept="image/x-png,image/jpeg,image/jpg,image/bmp" id="Image" className="form-control" name="Image" onChange={(event: any) => openFileDir(event)} />
                                            </fieldset>
                                            {IsChangingImage && <><MainBtn BtnText="Update Image" btnOnclickHandler={updateImage} /> <br /></>}
                                        </div>
                                        <div className="personal-details-form">
                                            <label style={FormDefaultStyling.Labels}>Email</label>
                                            <fieldset className="form-group">
                                                <input type="email" placeholder="Email" value={Email} id="Email" name="Email" className="form-control" onChange={(event: any) => setEmail(event.target.value)} />
                                            </fieldset>
                                            <label style={FormDefaultStyling.Labels}>Address - your home address</label>
                                            <fieldset className="form-group">
                                                <input type="text" placeholder="Address - your home address" value={Address} id="Address" name="Address" className="form-control" onChange={(event: any) => setAddress(event.target.value)} />
                                            </fieldset>
                                            <label style={FormDefaultStyling.Labels}>Select Gender</label>
                                            <fieldset className="form-group">
                                                <select className="form-control" onChange={(event: any) => setGender(event.target.value)} id="Gender" name="Gender" value={Gender}>
                                                    <optgroup>
                                                        <option value="">Select Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </optgroup>
                                                </select>
                                            </fieldset>
                                            <label style={FormDefaultStyling.Labels}>Date of Birth</label>
                                            <fieldset className="form-group">
                                                <input type="date" placeholder="Date of Birth" value={DateOfBirth} id="DateOfBirth" name="DateOfBirth" className="form-control" onChange={(event: any) => setDateOfBirth(event.target.value)} />
                                            </fieldset>
                                            <label style={FormDefaultStyling.Labels}>Cellphone Number</label>
                                            <fieldset className="form-group">
                                                <input type="text" placeholder="Cellphone Number" value={CellphoneNumber} id="CellphoneNumber" name="CellphoneNumber" className="form-control" onChange={(event: any) => setCellphoneNumber(event.target.value)} />
                                            </fieldset>
                                            <label style={FormDefaultStyling.Labels}>First Name</label>
                                            <fieldset className="form-group">
                                                <input type="text" placeholder="First Name" value={FirstName} id="FirstName" name="FirstName" className="form-control" onChange={(event: any) => setFirstName(event.target.value)} />
                                            </fieldset>
                                            <label style={FormDefaultStyling.Labels}>Last Name</label>
                                            <fieldset className="form-group">
                                                <input type="text" placeholder="Last Name" value={LastName} id="LastName" name="LastName" className="form-control" onChange={(event: any) => setLastName(event.target.value)} />
                                            </fieldset>
                                            <label style={FormDefaultStyling.Labels}>Username</label>
                                            <fieldset className="form-group">
                                                <input type="text" placeholder="Username" value={Username} id="Username" name="Username" className="form-control" onChange={(event: any) => setUsername(event.target.value)} />
                                            </fieldset>
                                            <label style={FormDefaultStyling.Labels}>About You</label>
                                            <br />
                                            <div className="card flex justify-content-center border-0 sm:w-3 md:w-3 lg:w-3 w-full h-4">
                                                <InputTextarea autoResize placeholder="Say something about yourself . . ." value={Bio} onChange={(e: any) => handleBioChanges(e.target.value)} rows={1} cols={30} />
                                            </div>
                                            <br />
                                            <MainBtn BtnText="Submit" btnOnclickHandler={updateUserDetails} />
                                            <br />
                                            {ReferralLink !== "" && <p style={{ fontSize: 15, textDecoration: "underline", color: "red" }}>{ReferralLink}</p>}
                                            {ReferralLink !== "" && <p>Copy and share it with who you want to be your referral.</p>}
                                            <MainBtn addClass="gen-referral" BtnText="Generate Referral Link" btnOnclickHandler={handleLinkGeneration} />
                                            {ReferralLink !== "" && <> <br />
                                                <p>Share the Link on</p>
                                                <ContentShare url={"/" + UserDetails.Email} /></>}
                                            <br />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Passwords" leftIcon="pi pi-lock mr-2">
                                    <div className="m-0">
                                        <h4>Change Password</h4>
                                        <div className="password-form">
                                            <label style={FormDefaultStyling.Labels}>Enter your Password</label>
                                            <div className="card flex justify-content-center border-0 m-1 sm:w-3 md:w-3 lg:w-3 w-full h-4">
                                                <Password value={UserPassword} onChange={(event: any) => setUserPassword(event.target.value)} toggleMask />
                                            </div>
                                            <label style={FormDefaultStyling.Labels}>Re Enter your Password</label>
                                            <div className="card flex justify-content-center border-0 m-1 sm:w-3 md:w-3 lg:w-3 w-full h-4">
                                                <Password value={UserRetypePassword} onChange={(event: any) => setUserRetypePassword(event.target.value)} toggleMask />
                                            </div>
                                            <MainBtn BtnText="Update" btnOnclickHandler={updatePassword} />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header={"Followers (" + UserFollowersList.length + ")"} leftIcon="pi pi-user mr-2">
                                    <div className="m-0">
                                        <FollowersRibbon removeFollower={removeFollower} viewProfile={viewProfile} List={UserFollowersList} />
                                    </div>
                                </TabPanel>
                                <TabPanel header={"Articles (" + ArticlesList.length + ")"} rightIcon="pi pi-book ml-2">
                                    <div className="m-0">
                                        <ArticlesRibbon viewArticle={viewArticle} List={ArticlesList} />
                                    </div>
                                </TabPanel>
                                <TabPanel header={"Notifications (" + UserFavouritesList.length + ")"} rightIcon="pi pi-bell ml-2">
                                    <div className="m-0">
                                        <NotificationsRibbon removeNotification={removeNotification} viewProfile={viewProfile} List={UserNotificationsList} />
                                    </div>
                                </TabPanel>
                                <TabPanel header={"Favorites (" + UserFavouritesList.length + ")"} rightIcon="pi pi-heart ml-2">
                                    <div className="m-0">
                                        <FavouritesRibbon removeFavourite={removeFavourite} toggleArticle={toggleArticle} List={UserFavouritesList} />
                                    </div>
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br /><br />
        <FooterMenu setModalStatus={setModalStatus} setModalHeader={setModalHeader} />
    </>
}

const FormDefaultStyling = {
    Labels: {
        fontSize: 13,
        margin: "0 0 0 5px"
    }
}


