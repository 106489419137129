import React, { useState, useEffect } from "react";

import { NavMenu } from "../../shared/NavMenu";
import MainBtn from "../../../../shared/MainBtn";
import DialogBox from "../../../../shared/DialogBox";
import { FooterMenu } from "../../shared/FooterMenu";

import Logo from "../../../../assets/images/app_logo.png";

import { useFetch } from "../../../../hooks/useFetch";
import { useBankingDetails } from "./hooks/useBankingDetails";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { User } from "../../../../utils/interfaces/User";
import { Results } from "../../../../utils/interfaces/Results";
import { IBanking } from "../../../../utils/interfaces/IBanking";
import { EnvironmentVariables } from "../../../../utils/Environment";
import { Withdrawals } from "../../../../utils/interfaces/Withdrawals";

import { setBankingData } from "../../../../utils/redux/Actions/MainActions";

export const Banking: React.FC<{}> = (): JSX.Element => {
    const EndPoints = EnvironmentVariables.EndPoints;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { runFetchPost } = useFetch();
    const { addBankingDetails, buildBanking, addWithdrawal, updateBankingDetails } = useBankingDetails();

    const [ModalHeader, setModalHeader] = useState("");
    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const UserDetails: User = useSelector((state: any) => state.UserDetails);
    const Authenticated: number = useSelector((state: any) => state.Authenticated);

    const [ArticleStats, setArticleStats] = useState<any>({});
    const [BankingDetails, setBankingDetails] = useState<IBanking>({} as IBanking);

    const [BankName, setBankName] = useState("");
    const [CardNumber, setCardNumber] = useState("");
    const [AccountNumber, setAccountNumber] = useState("");
    const [WithdrawnFunds, setWithdrawnFunds] = useState(0);
    const [CellphoneNumber, setCellphoneNumber] = useState("");

    const [ViewBalances, setViewBalances] = useState(false);
    const [IsUpdatingBanking, setIsUpdatingBanking] = useState(false);
    const [IsRequestingWithdrawal, setIsRequestingWithdrawal] = useState(false);

    // boolean states
    const [ModalStatus, setModalStatus] = useState(false);
    const [DialogStatus, setDialogStatus] = useState(false);

    useEffect(() => {
        if (Authenticated < 1)
            navigate("/blog");

        if (CardNumber == "") {
            let randNumber: any = (Math.random() * 1E16).toString();

            randNumber = randNumber.match(/.{1,4}/g) ?? [];
            setCardNumber(randNumber[0] + " " + randNumber[1] + " " + randNumber[2] + " " + randNumber[3]);
        }

        getArticlesStats();

    }, [Authenticated > 0]);

    const getArticlesStats = async () => {
        const params: User = { ID: Authenticated } as User;

        const statsResults: Results = await runFetchPost(params, EndPoints.Article.GetArticleStats);

        if (statsResults.Error) {
            setUpDialogValues(true, "Error", statsResults.ErrorDetail);
        } else {
            if (statsResults.Results.BankDetails !== null) {
                setArticleStats(statsResults.Results);
                setBankingDetails(statsResults.Results.BankDetails);

                setBankName(statsResults.Results.BankDetails.BankName);
                setCardNumber(statsResults.Results.BankDetails.CardNumber);
                setAccountNumber(statsResults.Results.BankDetails.AccountNumber);
                setCellphoneNumber(statsResults.Results.BankDetails.CellphoneNumber);

                setWithdrawnFunds(statsResults.Results.WithdrawalsHistory ? statsResults.Results.WithdrawalsHistory.Amount : 0);
            }
        }
    }

    const getWithDrawal = async () => {
        const params: Withdrawals = {} as Withdrawals;

        params.Paid = 1;
        params.UserID = Authenticated;
        params.Amount = BankingDetails.Balance;
        params.WithdrawalDate = "";
        params.LikesCount = ArticleStats.LikesCount;
        params.ViewsCount = ArticleStats.ViewsCount;
        params.ArticlesCount = ArticleStats.Articles;
        params.CommentsCount = ArticleStats.CommentsCount;

        if (AccountNumber !== "" && AccountNumber !== null) {
            setIsRequestingWithdrawal(true);

            let withdrawalResults: Results = await addWithdrawal(params);

            if (withdrawalResults.Error)
                setUpDialogValues(true, "Error", withdrawalResults.ErrorDetail);
            else
                setUpDialogValues(true, "Succes", "Withdrawal successful. You will receive your funds within 36 hours");

            setIsRequestingWithdrawal(false);
            getArticlesStats();

        } else {
            setUpDialogValues(true, "Error", "Please provide complete banking details first.");
        }
    }

    const setBankDetails = async () => {
        let params;
        let BankingResults: Results = {} as Results;

        if ((CellphoneNumber == "" || CellphoneNumber == null) ||
            (BankName == "" || BankName == null) ||
            (AccountNumber == "" || AccountNumber == null)) {
            setUpDialogValues(true, "Error", "Please fill in all required fields.");
        } else {
            setIsUpdatingBanking(true);

            const balance = ArticleStats.BankDetails.Balance - ((ArticleStats.WithdrawalsHistory != null) ? ArticleStats.WithdrawalsHistory.Amount : 0);

            if (BankingDetails.ID > 0) {
                params = buildBanking(BankName, AccountNumber, Authenticated, balance, CardNumber, CellphoneNumber, BankingDetails.ID);
                BankingResults = await updateBankingDetails(params);
            } else {
                params = buildBanking(BankName, AccountNumber, Authenticated, balance, CardNumber, CellphoneNumber, 0);
                BankingResults = await addBankingDetails(params);
            }

            if (BankingResults.Error) {
                setUpDialogValues(true, "Error", BankingResults.ErrorDetail);
            } else {
                params.ID = BankingResults.Results;

                dispatch(setBankingData(params));
                setIsUpdatingBanking(false);
                setUpDialogValues(true, "Success", "Banking Details updated successfully.");

            }
        }
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    return <>
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />

        <NavMenu setModalHeader={setModalHeader} setModalStatus={setModalStatus} />

        <section>
            <div className="app-banking-container">
                <div className="app-banking-content">
                    <div className="banking-card">
                        <div className="bank-card">
                            <div className="card-header">
                                <div className="card-logo">
                                    <img src={Logo} alt="card-logo" />
                                </div>
                            </div>
                            <div className="card-body">
                                <span><b>Card No:</b> {BankingDetails.CardNumber}</span>
                                <span><b>Card Holder:</b> {UserDetails.FirstName ? UserDetails.FirstName.charAt(0) + " " + UserDetails.LastName : ""}</span>
                                <span><b>Account Number:</b> {BankingDetails.AccountNumber}</span>
                                <span><b>Bank Name:</b> {BankingDetails.BankName}</span>
                            </div>
                        </div>
                    </div>
                    <div className="banking-balances">
                        <MainBtn BtnText="View your Balances" btnOnclickHandler={() => setViewBalances(!ViewBalances)} />
                        <br />
                        {ViewBalances ? <>
                            <p><b>Balance</b>: R{ArticleStats.BankDetails.Balance + ".00"}</p>
                            <p><b>Available Funds</b>: R{ArticleStats.BankDetails.Balance - ((ArticleStats.WithdrawalsHistory != null) ? ArticleStats.WithdrawalsHistory.Amount : 0)}.00</p>
                            <p><b>Withdrawn Funds</b>: R{Number(WithdrawnFunds)}.00</p>
                            {(ArticleStats.BankDetails.Balance - ((ArticleStats.WithdrawalsHistory != null) ? ArticleStats.WithdrawalsHistory.Amount : 0)) >= 100 ? <>
                                <MainBtn BtnStatus={IsRequestingWithdrawal} BtnText={IsRequestingWithdrawal ? "Processing" : "Request Withdrawal"} btnOnclickHandler={getWithDrawal} />
                                <br />
                            </> : ""}
                        </> : ""}
                    </div>
                    <div className="banking-form">
                        <label style={FormDefaultStyling.Labels}>Account Number</label>
                        <fieldset className="form-group">
                            <input type="text" placeholder="Account Number" value={AccountNumber} id="AccountNumber" name="AccountNumber" className="form-control" onChange={(event: any) => setAccountNumber(event.target.value)} />
                        </fieldset>
                        <label style={FormDefaultStyling.Labels}>Bank Name</label>
                        <fieldset className="form-group">
                            <input type="text" placeholder="Bank Name" value={BankName} id="BankName" name="BankName" className="form-control" onChange={(event: any) => setBankName(event.target.value)} />
                        </fieldset>
                        <label style={FormDefaultStyling.Labels}>Linked Cellphone Number</label>
                        <fieldset className="form-group">
                            <input type="text" placeholder="Banking Linked Cellphone Number" value={CellphoneNumber} id="CellphoneNumber" name="CellphoneNumber" className="form-control" onChange={(event: any) => setCellphoneNumber(event.target.value)} />
                        </fieldset>
                        <MainBtn BtnStatus={IsUpdatingBanking} BtnText={IsUpdatingBanking ? "Processing" : "Submit"} btnOnclickHandler={setBankDetails} />
                    </div>
                </div>
            </div>
        </section>
        <br /><br />
        <FooterMenu setModalStatus={setModalStatus} setModalHeader={setModalHeader} />
    </>
}


const FormDefaultStyling = {
    Labels: {
        fontSize: 13,
        margin: "0 0 0 5px"
    }
}
