import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Avatar } from "primereact/avatar";

import { Comment } from "../../../utils/interfaces/Comment";
import { Followers } from "../../../utils/interfaces/Followers";
import { ICommentLike } from "../../../utils/interfaces/ICommentLike";
import { ICommentReply } from "../../../utils/interfaces/ICommentReply";
import CommentReplyHolder from "../screens/ArticleScreen/components/CommentReplyHolder";
import CommentReplyForm from "../screens/ArticleScreen/components/CommentReplyForm";

const CommentHolder: React.FC<{
    removeComment: any,
    ClearText: boolean,
    CommentsList: Comment[],
    FollowersList: Followers[],
    CommentsLikes: ICommentLike[],
    likeComment: (type: number, id: number) => void,
    IsCommentLiking: boolean
    IsReplyingComment: boolean,
    CommentsRepliesList: ICommentReply[]
    createCommentReply: (text: string, id: number, Mentioned: any[]) => void,
    previewUser: (comment: Comment) => void,
    previewReplyUser: (reply: ICommentReply) => void,
    removeCommentReply: (id: number) => void
}> = ({ removeComment,
    CommentsList,
    previewUser,
    CommentsLikes,
    ClearText,
    FollowersList,
    createCommentReply,
    likeComment,
    CommentsRepliesList,
    IsReplyingComment,
    previewReplyUser,
    removeCommentReply,
    IsCommentLiking }): JSX.Element => {
        const [SelectedReply, setSelectedReply] = useState<number>(0);

        const Authenticated: number = useSelector((state: any) => state.Authenticated);

        const ImagePath: string = "https://nhlavutelo.co.za/Images/";

        const replyComment = (id: number) => {
            if (id == SelectedReply)
                setSelectedReply(0);
            else
                setSelectedReply(id);
        }

        return <>
            {CommentsList.sort(function (a: any, b: any) {
                return b.ID - a.ID;
            }).map((comment: any, index: number) => {
                const names = comment.UserNames.split(" ");

                return <div key={index} className="comments-container" id={comment.ID}>
                    <div className="comment-holder">
                        <div className="comment-user-icon" onClick={() => previewUser(comment)}>
                            {comment.ImageName == null ?
                                <div className="user-initials"><span>{names[0].charAt(0) + "" + names[1].charAt(0)}</span></div> :
                                <Avatar image={ImagePath + comment.ImageName} shape="circle" />
                            }
                        </div>
                        <div className="comment-user-names" onClick={() => previewUser(comment)}><span>{comment.UserNames}</span><small>{comment.DateCreated}</small></div>
                        {Authenticated == comment.UserID && <div className="delete-comment" onClick={() => removeComment(comment.ID)}>
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                            </svg></span>
                        </div>}
                    </div>
                    <div className="comment-message">
                        <p>{comment.Text}</p>
                        <div className="comment-likes-holder" style={{ pointerEvents: IsCommentLiking ? "none" : "all" }}>
                            <span onClick={() => likeComment(1, comment.ID)} className="bi bi-hand-thumbs-up">({CommentsLikes !== undefined ?
                                CommentsLikes.filter((like: ICommentLike) => { return (like.CommentID == comment.ID && like.CommentLike > 0) }).length
                                : 0})</span>
                            <span onClick={() => likeComment(2, comment.ID)} className="bi bi-hand-thumbs-down">({CommentsLikes !== undefined ?
                                CommentsLikes.filter((like: ICommentLike) => { return (like.CommentID == comment.ID && like.CommentDislike > 0) }).length
                                : 0})</span>
                            <span style={{ pointerEvents: IsReplyingComment ? "none" : "all" }} onClick={() => replyComment(comment.ID)} className="bi bi-reply-fill">({CommentsRepliesList.filter((item: ICommentReply) => {
                                return (item.CommentID == comment.ID);
                            }).length})</span>
                        </div>
                        <div style={{ margin: "10px 0 0 0", borderLeft: "1px solid #cccccc", padding: "0 0 0 10px" }}>
                            {SelectedReply == comment.ID && <>
                                <CommentReplyHolder
                                    IsReplyingComment={IsReplyingComment}
                                    removeCommentReply={removeCommentReply}
                                    previewReplyUser={previewReplyUser}
                                    FollowersList={FollowersList}
                                    CommentsReplyList={CommentsRepliesList.length > 0 ?
                                        CommentsRepliesList.filter((item: ICommentReply) => {
                                            return (item.CommentID == comment.ID);
                                        }) : []} />
                                <CommentReplyForm FollowersList={FollowersList} ClearText={ClearText} CommentID={comment.ID} createCommentReply={createCommentReply} />
                            </>}
                        </div>
                    </div>
                </div>
            })}

        </>
    }

export default CommentHolder;