import React from "react";

import {
    BrowserRouter as Router,
    useRoutes,
} from "react-router-dom";

import "./App.css";
import "./TheBlog.css";

// prime faces
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

// screens
import HomeScreen from "./screens/HomeScreen";
import AboutUsScreen from "./screens/AboutScreen";
import ReferralScreen from "./screens/ReferralScreen";
import ServicesScreen from "./screens/ServicesScreen";
import AdminScreen from "./screens/Admin/AdminScreen";
import ContactUsScreen from "./screens/ContactUsScreen";
import DownloadsScreen from "./screens/DownloadsScreen";
import WriteEarnScreen from "./screens/WriteEarnScreen";
import BlogsScreen from "./screens/BlogScreen/BlogScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";


import { Write } from "./screens/BlogScreen/screens/Write/Write";
import { Banking } from "./screens/BlogScreen/screens/Banking/Banking";
import { Profile } from "./screens/BlogScreen/screens/Profile/Profile";
import { ProfilePreview } from "./screens/BlogScreen/screens/Profile/Preview";
import { ArticleView } from "./screens/BlogScreen/screens/ArticleScreen/ArticleView";

// redux //
import { Provider } from "react-redux";
import allReducers from "./utils/redux/Reducers/Index";
import { legacy_createStore as createStore } from 'redux';

const store = createStore(allReducers);

const AppRoutes = () => {
    const routes = useRoutes(
        [
            { path: "/", element: <HomeScreen /> },
            { path: "/about", element: <AboutUsScreen /> },
            { path: "/services", element: <ServicesScreen /> },
            { path: "/downloads", element: <DownloadsScreen /> },
            { path: "/contact-us", element: <ContactUsScreen /> },
            { path: "/banking", element: <Banking /> },
            { path: "/write-earn", element: <WriteEarnScreen /> },
            { path: "/profile/:names", element: <Profile /> },
            { path: "/write", element: <Write /> },
            { path: "/blog", element: <BlogsScreen /> },
            { path: "/preview/:names", element: <ProfilePreview /> },
            { path: "/referral/:email", element: <ReferralScreen /> },
            { path: "/admin-app", element: <AdminScreen /> },
            { path: "/reset-password", element: <ResetPasswordScreen /> },
            { path: "/article-view/:id", element: <ArticleView /> }
        ]
    )

    return routes;
}

const App = () => {
    return <>
        <Provider store={store}>
            <Router>
                <AppRoutes />
            </Router>
        </Provider>
    </>
}

export default App;