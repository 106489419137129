export const EnvironmentVariables: any = {
    URLs: {
        MainUrl: "https://nhlavutelo.co.za/api", // live - https://nhlavutelo.co.za/api/ ? locally: https://localhost:44382/
        AppUrl: "https://nhlavutelo.co.za", // live - https://nhlavutelo.co.za/api/ ? locally: https://localhost:44382/
        NotificationsUrl: "",
    },
    EndPoints: {
        User: {
            GetUser: "/Users/GetUser",
            CreateUser: "/Users/CreateUser",
            SearchUser: "/Users/SearchUser",
            UpdateUser: "/Users/UpdateUser",
            GetAuthors: "/Users/GetAuthors",
            GetUserData: "/Users/GetUserData",
            UpdateImage: "/Users/UpdateImage",
            ValidateUser: "/Users/ValidateUser",
            GetUsersCount: "/Users/GetUsersCount",
            ResetPassword: "/Users/ResetPassword",
            UpdatePassword: "/Users/UpdatePassword",
            GetAuthorsCount: "/Users/GetAuthorsCount",
        },
        Referrals: {
            CreateReferral: "/Referral/CreateReferral",
            GetUserReferrals: "/Referral/GetUserReferrals",
            GetReferrals: "/Referral/GetReferrals"
        },
        Subscriptions: {
            GetSubscriptions: "/Subscriptions/GetSubscriptions",
            ContactSubscribers: "/Subscriptions/ContactSubscribers",
            CreateSubscription: "/Subscriptions/CreateSubscription",
        },
        Comment: {
            GetComments: "/UserComments/GetComments",
            CreateComment: "/UserComments/CreateComment",
            DeleteComment: "/UserComments/DeleteComment",
            GetCommentsCount: "/UserComments/GetCommentsCount",
        },
        Contact: {
            EmailAllUsers: "/Contact/EmailAllUsers",
            SendUserEmail: "/Contact/SendUserEmail",
        },
        Article: {
            GetArticle: "/Articles/GetArticle",
            GetArticles: "/Articles/GetArticles",
            ReportArticle: "/Articles/ReportArticle",
            GetArticleData: "/Articles/GetArticleData",
            PublishArticle: "/Articles/PublishArticle",
            CreateArticle: "/Articles/CreateArticle",
            UpdateArticle: "/Articles/UpdateArticle",
            GetArticleStats: "/Articles/GetArticleStats",
            GetUserArticles: "/Articles/GetUserArticles",
            GetArticlesCount: "/Articles/GetArticlesCount",
            GetUnpublishedCount: "/Articles/GetUnplishedArticles",
            GetUnplishedArticles: "/Articles/GetUnplishedArticles",
        },
        ArticleView: {
            GetViews: "/ArticleViews/GetViews",
            SetViews: "/ArticleViews/SetViews",
            GetViewsCount: "/ArticleViews/GetViewsCount",
            GetArticleViews: "/ArticleViews/GetArticleViews",
        },
        Download: {
            GetDownloads: "/Downloads/GetDownloads",
            SetDownloads: "/Downloads/SetDownloads",
            GetDownloadsByDate: "/ArticleViews/GetDownloadsByDate",
        },
        Like: {
            SetLike: "/UserLikes/SetLike",
            GetLikes: "/UserLikes/GetLikes",
            SetDisLike: "/UserLikes/SetDisLike",
            DeleteLike: "/UserLikes/DeleteLike",
            GetLikesCount: "/UserLikes/GetLikesCount",
            GetArticleLikes: "/UserLikes/GetArticleLikes"
        },
        Follower: {
            GetFollowers: "/Followers/GetFollowers",
            CreateFollower: "/Followers/CreateFollower",
            DeleteFollower: "/Followers/DeleteFollower",
            GetUserFollowers: "/Followers/GetUserFollowers",
            GetFollowersCount: "/Followers/GetFollowersCount",
        },
        Banking: {
            GetBalance: "/Bankings/GetBalance",
            GetBankingDetails: "/Bankings/GetBankingDetails",
            SetBankingDetails: "/Bankings/SetBankingDetails",
            UpdateBankingDetails: "/Bankings/UpdateBankingDetails",
            GetUserBankingDetails: "/Bankings/GetUserBankingDetails",
        },
        Quotes: {
            GetQuotes: "/Quote/GetQuotes",
            CreateQuote: "/Quote/CreateQuote",
            UpdateQuote: "/Quote/UpdateQuote",
        },
        Withdrawals: {
            GetWithdrawals: "/Withdrawals/GetWithdrawals",
            GetUserWithdrawals: "/Withdrawals/GetUserWithdrawals",
            CreateWithdrawal: "/Withdrawals/CreateWithdrawal",
        },
        Notifications: {
            GetNotifications: "/Notifications/GetNotifications",
            CreateNotification: "/Notifications/CreateNotification",
            UpdateNotification: "/Notifications/UpdateNotification",
        },
        CommentsLikes: {
            CreateCommentLike: "/CommentsLikes/CreateCommentLike",
            GetArticleCommentsLikes: "/CommentsLikes/GetArticleCommentsLikes",
        },
        CommentsReplies: {
            DeleteReply: "/CommentsReplies/DeleteReply",
            GetCommentsReplies: "/CommentsReplies/GetCommentsReplies",
            CreateCommentReply: "/CommentsReplies/CreateCommentReply",
            GetArticleCommentsReplies: "/CommentsReplies/GetArticleCommentsReplies",
        },
        Favourites: {
            RemoveFavourite: "/Favourites/RemoveFavourite",
            CreateFavourite: "/Favourites/CreateFavourite",
            GetUserFavourites: "/Favourites/GetUserFavourites",
            IfUserLovesArticle: "/Favourites/IfUserLovesArticle",
        },
        Mentions: {
            CreateMention: "/Mentions/CreateMention",
        }
    }
}