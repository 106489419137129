export const Authenticated = (state = 0, action: any) => {
    switch (action.type) {
        case "ISAUTH":
            return action.payload;
        default:
            return state;
    }
}

export const UserDetails = (state = {}, action: any) => {
    switch (action.type) {
        case "USERDETAILS":
            return action.payload;
        default:
            return state;
    }
}

export const PreviewUser = (state = {}, action: any) => {
    switch (action.type) {
        case "PREVIEWUSER":
            return action.payload;
        default:
            return state;
    }
}

export const CommentsData = (state = [], action: any) => {
    switch (action.type) {
        case "COMMENTSDATA":
            return action.payload;
        default:
            return state;
    }
}

export const Banking = (state = {}, action: any) => {
    switch (action.type) {
        case "BANKING":
            return action.payload;
        default:
            return state;
    }
}

export const Articles = (state = [], action: any) => {
    switch (action.type) {
        case "ARTICLES":
            return action.payload;
        default:
            return state;
    }
}

export const CurrentArticle = (state = {}, action: any) => {
    switch (action.type) {
        case "ARTICLE":
            return action.payload;
        default:
            return state;
    }
}

export const Likes = (state = {}, action: any) => {
    switch (action.type) {
        case "LIKES":
            return action.payload;
        default:
            return state;
    }
}

export const Views = (state = [], action: any) => {
    switch (action.type) {
        case "VIEWS":
            return action.payload;
        default:
            return state;
    }
}

export const Favourites = (state = [], action: any) => {
    switch (action.type) {
        case "FAVOURITES":
            return action.payload;
        default:
            return state;
    }
}
export const Notifications = (state = [], action: any) => {
    switch (action.type) {
        case "NOTIFICATIONS":
            return action.payload;
        default:
            return state;
    }
}

export const UserFollowers = (state = [], action: any) => {
    switch (action.type) {
        case "USERFOLLOWERS":
            return action.payload;
        default:
            return state;
    }
}

export const AuthorFollowers = (state = [], action: any) => {
    switch (action.type) {
        case "AUTHORFOLLOWERS":
            return action.payload;
        default:
            return state;
    }
}
