import React, { useState } from "react";

import { Results } from "../../../utils/interfaces/Results";
import { ContactForm } from "../../../utils/interfaces/ContactForm";

import { MainServices } from "../../../utils/Services";

import MainBtn from "../../../shared/MainBtn";
import DialogBox from "../../../shared/DialogBox";

const SendingEmails: React.FC<{}> = (): JSX.Element => {
    const Services: MainServices = new MainServices();

    const [Subject, setSubject] = useState("");
    const [Message, setMessage] = useState("");

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [DialogStatus, setDialogStatus] = useState(false);

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const submitRequest = async () => {
        const EmailInfo: ContactForm = {} as ContactForm;

        EmailInfo.Email = "";
        EmailInfo.Names = "";
        EmailInfo.Message = Message;
        EmailInfo.Subject = Subject;

        setUpDialogValues(true, "Processing", "Please wait . . .");

        const EmailInfoResults: Results = await Services.EmailAllUsers(EmailInfo);

        if (EmailInfoResults.Error) {
            setUpDialogValues(true, "Error", EmailInfoResults.ErrorDetail);
        } else {
            setUpDialogValues(true, "Success", EmailInfoResults.Results);
        }
    }


    return <>
        <br />
        <h4>Emailing</h4>
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />
        <div className="contact-us-form" style={{ width: "40%" }}>
            <fieldset className="form-group">
                <input type="text" placeholder="What's this about ?" onChange={(event: any) => setSubject((event.target as HTMLInputElement).value)} value={Subject} id="Subject" name="Subject" className="form-control" />
            </fieldset>
            <fieldset className="form-group">
                <textarea placeholder="Your message here . . ." onChange={(event: any) => setMessage((event.target as HTMLInputElement).value)} value={Message} id="Message" name="Message" className="form-control"></textarea>
            </fieldset>
            <MainBtn btnOnclickHandler={submitRequest} BtnText={"Submit"} />
        </div>
    </>
}

export default SendingEmails;