import React from "react";
import { Followers } from "../../../../../utils/interfaces/Followers";
import { User } from "../../../../../utils/interfaces/User";

const FollowersRibbon: React.FC<{ List: Followers[], removeFollower?: any, viewProfile: (user: User) => void }> = ({ List, removeFollower, viewProfile }) => {
    const ImagePath: string = "https://nhlavutelo.co.za/images/";

    return <>
        {List.length > 0 && List.map((follower: any, index: number) => {
            const names = follower.UserNames.split(" ");
            return <div onClick={() => viewProfile(follower)} className="follow-card" key={index}>
                <div className="follower-content">
                    {follower.ImageName == null ? <div className="follower-image">
                        <p>
                            {names[0].charAt(0).replace(/\s/g, '') + "" + names[1].charAt(0).replace(/\s/g, '')}
                        </p>
                    </div> : <div className="follower-image" style={{ background: "url(" + ImagePath + follower.ImageName + ")" }}></div>}
                    <div className="follower-info">
                        <div className="follower-names">
                            <p>{follower.IsAuthor ? "Author" : "Standard Account"}</p>
                            <p>{follower.UserNames.substring(0, 35)}</p>
                        </div>
                    </div>
                    <div onClick={() => removeFollower(follower)} style={{ color: "red", cursor: "pointer" }} className="follower-options"><i className="bi bi-x-circle-fill"></i></div>
                </div>
            </div>
        })}
    </>
}

export default FollowersRibbon;