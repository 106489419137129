import React, { useState } from "react";

const ContentCard: React.FC<{ Heading: string, Message: string, ImagePath: string }> = ({ Heading, Message, ImagePath }) => {
    return <>
        <div className="inner-content-item">
            <div className="item-summary">
                <h4>{Heading}</h4>
                {ImagePath ? <img src={ImagePath} alt="content-card" /> : ""}
                <p>{Message}</p>
            </div>
        </div>
    </>
}

export default ContentCard;

// to use this - the parent container that's to contain this should have a position property of relative - this should be defined in the css