import React, { useState, useEffect } from "react";

import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import SendingEmails from "./components/Emailing";
import DialogBox from "../../shared/DialogBox";
import UnpublishedArticles from "./components/UnpublishedArticles";

import { Results } from "../../utils/interfaces/Results";

import { MainServices } from "../../utils/Services";
import ChartOverview from "./components/Chart";
import { useFetch } from "../../hooks/useFetch";

const AdminScreen: React.FC<{}> = (): JSX.Element => {
    const { runFetchPost } = useFetch();

    const Services: MainServices = new MainServices();

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [DialogStatus, setDialogStatus] = useState(false);

    const [UsersCount, setUsersCount] = useState(0);
    const [LikesCount, setLikesCount] = useState(0);
    const [ViewsCount, setViewsCount] = useState(0);
    const [AuthorsCount, setAuthorsCount] = useState(0);
    const [CommentsCount, setCommentsCount] = useState(0);
    const [ArticlesCount, setArticlesCount] = useState(0);
    const [FollowersCount, setFollowersCount] = useState(0);
    const [UnpublishedCount, setUnpublishedCount] = useState(0);

    const [ChartDataList, setChartDataList] = useState<number[]>([]);

    const [View, setView] = useState("");

    useEffect(() => {
        getAuthorsCount();

    }, []);

    const toggleView = (type: string) => {
        if (type == View)
            setView("");
        else
            setView(type);
    }

    const getAuthorsCount = async () => {
        const AuthorsResults: Results = await Services.GetAuthorsCount();

        if (AuthorsResults.Error) {
            setUpDialogValues(true, "Error", AuthorsResults.ErrorDetail);
        } else {
            setAuthorsCount(AuthorsResults.Results);
            setChartDataList([...ChartDataList, AuthorsResults.Results]);
            getUsersCount();
        }
    }

    const getUsersCount = async () => {
        const UserssResults: Results = await Services.GetUsersCount();

        if (UserssResults.Error) {
            setUpDialogValues(true, "Error", UserssResults.ErrorDetail);
        } else {
            setUsersCount(UserssResults.Results);
            setChartDataList([...ChartDataList, UserssResults.Results]);
            getArticlesCount();
        }
    }

    const getArticlesCount = async () => {
        const ArticlesResults: Results = await Services.GetArticlesCount();

        if (ArticlesResults.Error) {
            setUpDialogValues(true, "Error", ArticlesResults.ErrorDetail);
        } else {
            setArticlesCount(ArticlesResults.Results);
            setChartDataList([...ChartDataList, ArticlesResults.Results]);
            getCommentsCount();
        }
    }

    const getCommentsCount = async () => {
        const CommentsResults: Results = await Services.GetCommentsCount();

        if (CommentsResults.Error) {
            setUpDialogValues(true, "Error", CommentsResults.ErrorDetail);
        } else {
            setCommentsCount(CommentsResults.Results);
            setChartDataList([...ChartDataList, CommentsResults.Results]);
            getLikesCount();
        }
    }

    const getLikesCount = async () => {
        const LikesResults: Results = await Services.GetLikesCount();

        if (LikesResults.Error) {
            setUpDialogValues(true, "Error", LikesResults.ErrorDetail);
        } else {
            setLikesCount(LikesResults.Results);
            setChartDataList([...ChartDataList, LikesResults.Results]);
            getViewsCount();
        }
    }

    const getViewsCount = async () => {
        const ViewsResults: Results = await Services.GetViewsCount();

        if (ViewsResults.Error) {
            setUpDialogValues(true, "Error", ViewsResults.ErrorDetail);
        } else {
            setViewsCount(ViewsResults.Results);
            setChartDataList([...ChartDataList, ViewsResults.Results]);
            getFollowersCount();
        }
    }

    const getFollowersCount = async () => {
        const FollowerResults: Results = await Services.GetFollowersCount();

        if (FollowerResults.Error) {
            setUpDialogValues(true, "Error", FollowerResults.ErrorDetail);
        } else {
            setFollowersCount(FollowerResults.Results);
            setChartDataList([...ChartDataList, FollowerResults.Results]);
            getUnpublishedCount();
        }
    }

    const getUnpublishedCount = async () => {
        const UnpublishedResults: Results = await Services.GetUnpublishedCount();

        if (UnpublishedResults.Error) {
            setUpDialogValues(true, "Error", UnpublishedResults.ErrorDetail);
        } else {
            setUnpublishedCount(UnpublishedResults.Results.length);
            //getFollowersCount();

        }
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const sendReminders = async () => {
        toggleView("Emailing");
        
    }

    const birthdayGreetings = async () => {

    }

    return <>
        <NavBar />
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />
        <section>
            <div className="admin-app-container">
                <div className="admin-app-content">
                    <div className="reporting">
                        <div className="reports-container">
                            <h4>Overview</h4>
                            <div className="all-reports">
                                <div className="counts">
                                    <span>Total Articles</span>
                                    <div className="count-content">
                                        <span><i className="bi bi-journal-text"></i></span>
                                        <span>{ArticlesCount}</span>
                                    </div>
                                </div>
                                <div className="counts" style={{ backgroundColor: "red" }}>
                                    <span>Total Likes</span>
                                    <div className="count-content">
                                        <span><i className="bi bi-hand-thumbs-up"></i></span>
                                        <span>{LikesCount}</span>
                                    </div>
                                </div>
                                <div className="counts" style={{ backgroundColor: "rgb(230, 0, 92)" }}>
                                    <span>Total Comments</span>
                                    <div className="count-content">
                                        <span><i className="bi bi-chat"></i></span>
                                        <span>{CommentsCount}</span>
                                    </div>
                                </div>
                                <div className="counts" style={{ backgroundColor: "green" }}>
                                    <span>Total Views</span>
                                    <div className="count-content">
                                        <span><i className="bi bi-eye"></i></span>
                                        <span>{ViewsCount}</span>
                                    </div>
                                </div>
                                <div className="counts" style={{ backgroundColor: "purple" }}>
                                    <span>Total Users</span>
                                    <div className="count-content">
                                        <span><i className="bi bi-person-circle"></i></span>
                                        <span>{UsersCount}</span>
                                    </div>
                                </div>
                                <div className="counts" style={{ backgroundColor: "brown" }}>
                                    <span>Total Followers</span>
                                    <div className="count-content">
                                        <span><i className="bi bi-person-circle"></i></span>
                                        <span>{FollowersCount}</span>
                                    </div>
                                </div>
                                <div className="counts" style={{ backgroundColor: "orange" }}>
                                    <span>Total Authors</span>
                                    <div className="count-content">
                                        <span><i className="bi bi-person-circle"></i></span>
                                        <span>{AuthorsCount}</span>
                                    </div>
                                </div>
                                <div className="counts" style={{ backgroundColor: "indigo" }}>
                                    <span>Unpublished Articles</span>
                                    <div className="count-content">
                                        <span><i className="bi bi-person-circle"></i></span>
                                        <span>{UnpublishedCount}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="controls-container">
                                <button onClick={sendReminders} type="button" className="btn btn-outline-primary">Send Reminders</button>
                                <button onClick={birthdayGreetings} type="button" className="btn btn-outline-primary">Birthday Greetings</button>
                                <button onClick={() => toggleView("Emailing")} type="button" className="btn btn-outline-primary">Send Reminders</button>
                                <button onClick={() => toggleView("Emailing")} type="button" className="btn btn-outline-primary">Emailing</button>
                                <button onClick={() => toggleView("Chart")} type="button" className="btn btn-outline-secondary">Chart</button>
                                <button onClick={() => toggleView("Articles")} type="button" className="btn btn-outline-success">Articles</button>
                                {/* <button type="button" className="btn btn-outline-danger">Danger</button>
                                <button type="button" className="btn btn-outline-warning">Warning</button>
                                <button type="button" className="btn btn-outline-info">Info</button>
                                <button type="button" className="btn btn-outline-dark">Dark</button> */}
                            </div>
                            {View == "Emailing" && <SendingEmails />}
                            {View == "Articles" && <UnpublishedArticles />}
                            {View == "Chart" && <ChartOverview Articles={ArticlesCount} Authors={AuthorsCount} Likes={LikesCount} Views={ViewsCount} Comments={CommentsCount} Users={UsersCount} Followers={FollowersCount} />}

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
}

export default AdminScreen;