import React, { useRef } from "react";
import { useSelector } from "react-redux";

import { Avatar } from "primereact/avatar";

import { Followers } from "../../../../../utils/interfaces/Followers";
import { ICommentReply } from "../../../../../utils/interfaces/ICommentReply";

const CommentReplyHolder: React.FC<{
    removeCommentReply: (id: number) => void,
    CommentsReplyList: ICommentReply[],
    FollowersList: Followers[],
    IsReplyingComment: boolean,
    previewReplyUser: (commentReply: ICommentReply) => void
}> = ({ removeCommentReply,
    CommentsReplyList,
    FollowersList,
    IsReplyingComment,
    previewReplyUser }): JSX.Element => {
        const commentContainer: any = useRef<HTMLDivElement>();

        const Authenticated: number = useSelector((state: any) => state.Authenticated);

        const ImagePath: string = "https://nhlavutelo.co.za/Images/";

        return <>
            {CommentsReplyList.sort(function (a: any, b: any) {
                return b.ID - a.ID;
            }).map((commentReply: any, index: number) => {
                const names = commentReply.UserNames.split(" ");

                return <div ref={commentContainer} key={index} className="comments-container" id={commentReply.ID}>
                    <div className="comment-holder">
                        <div className="comment-user-icon" onClick={() => previewReplyUser(commentReply)}>
                            {commentReply.ImageName == null ?
                                <div className="user-initials"><span>{names[0].charAt(0) + "" + names[1].charAt(0)}</span></div> :
                                <Avatar image={ImagePath + commentReply.ImageName} shape="circle" />
                            }
                        </div>
                        <div className="comment-user-names" onClick={() => previewReplyUser(commentReply)}><span>{commentReply.UserNames}</span><small>{commentReply.DateCreated}</small></div>
                        {Authenticated == commentReply.UserID &&
                            <div style={{ pointerEvents: IsReplyingComment ? "none" : "all" }} className="delete-comment" onClick={() => removeCommentReply(commentReply.ID)}>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                </svg></span>
                            </div>}
                    </div>
                    <div className="comment-message">
                        <p>{commentReply.Text}</p>
                        <div className="comment-likes-holder" style={{ pointerEvents: commentReply ? "none" : "all" }}>
                        </div>
                    </div>
                </div>
            })}

        </>
    }

export default CommentReplyHolder;