import React, { useState } from "react";

import NavBar from "../shared/NavBar";
import Footer from "../shared/Footer";
import MainBtn from "../shared/MainBtn";
import DialogBox from "../shared/DialogBox";
import ContentCard from "../shared/ContentCard";

import { FormValidation } from "../hooks/FormValidation";

import Quote from "../utils/interfaces/Quote";
import { Results } from "../utils/interfaces/Results";

import { MainServices } from "../utils/Services";

const ServicesScreen: React.FC<{}> = (): JSX.Element => {
    const Services: MainServices = new MainServices();
    const { validateForm, validateEmail } = FormValidation();

    const [Names, setNames] = useState("");
    const [Email, setEmail] = useState("");
    const [Subject, setSubject] = useState("");
    const [Message, setMessage] = useState("");
    const [Service, setService] = useState("");
    const [ServicePlan, setServicePlan] = useState("");

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [DialogStatus, setDialogStatus] = useState(false);

    const Months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const Today = new Date().getFullYear() + " " + Months[new Date().getMonth()] + " " + new Date().getDate() + " " + new Date().getHours() + "h" + new Date().getMinutes();

    const Contents: any[] = [{
        header: "Web & Desktop App Development", message: `From your static, dynamic or CMS and eCommerce websites, we will develop anything that you can think of. If you can tell me about it - we will get it done for you. You can either rent this or own them. Meaning you can decide to pay a monthly fee based on the type of you want for a specific period or pay for the entire development of the app and own it.`
    }, {
        header: "Mobile Apps", message: `We develop both Android and iOS apps, irrespective of what you want them to do. If you can describe it to me - we will will do it. You can either rent this or own them. Meaning you can decide to pay a monthly fee based on the type of you want for a specific period or pay for the entire development of the app and own it.`
    }, {
        header: "Coding School", message: `Scholar is our digital learning platform. You can go from being a beginner to a prodigy in web development. Learning HTML5, CSS3 and JavaScript along with its frameworks and libraries like jQuery, React and VueJS. All courses can be done in a month and this is completely up to you - the quicker you learn, the sooner you finish.`
    }, {
        header: "Public Speaking", message: `Listen to the videos on the home page - or ask around - and if you are somewhat enthused by what you will both hear and learn - then do book us to come and speak in whatever your event - except for funerals.`
    }
    ];

    const toggleQuoteForm = () => {
        (document.querySelector(".get-quote-form") as HTMLDivElement).scrollIntoView();
        (document.querySelector(".get-quote-form") as HTMLDivElement).scrollIntoView({ block: "end" });
        (document.querySelector(".get-quote-form") as HTMLDivElement).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    const toggleScholar = () => {
        (window as any).open("https://scholar.treasuremkhonto.co.za", "_blank");
    }

    const submitQuote = async () => {
        const formFields = Array.from(document.querySelectorAll(".get-quote-form input, .get-quote-form select, .get-quote-form textarea"));
        const isFormValid = validateForm(formFields);
        const isEmailValid = validateEmail(Email);
        const params: Quote = {} as Quote;

        if (!isFormValid) {
            setUpDialogValues(true, "Error", "Please fill in all required fields");
            return;
        }

        if (!isEmailValid) {
            setUpDialogValues(true, "Error", "Please provide a valid email address");
            return;
        }

        if (isEmailValid && isFormValid) {
            setUpDialogValues(true, "Processing", "Please wait...");

            params.Names = Names;
            params.Email = Email;
            params.InBusiness = 0;
            params.QuoteDate = Today
            params.Subject = Subject;
            params.Message = Message;
            params.ServiceType = Service;
            params.ServicePlan = ServicePlan;

            const QuoteResults: Results = await Services.CreateQuote(params);

            if (QuoteResults.Error) {
                setUpDialogValues(true, "Error", QuoteResults.ErrorDetail);
            } else {
                setNames("");
                setEmail("");
                setSubject("");
                setMessage("");
                setService("");
                setServicePlan("");

                setUpDialogValues(true, "Success", "Thank you for reaching out, will get back to you shortly.");
            }
        }
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    return <>
        <NavBar />
        <DialogBox DialogMessage={DialogMessage} DialogHeader={DialogHeader} DialogStatus={DialogStatus} closeDialog={closeDialog} />
        <section>
            <div className="services-container">
                <div className="services-content">
                    <h4>Here's some of our services</h4>
                    <div className="services-types">
                        <div className="service-item">
                            <div className="service-icon">
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" className="bi bi-pc-display" viewBox="0 0 16 16">
                                    <path d="M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V1Zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0ZM9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5ZM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5ZM1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2H1.5Z" />
                                </svg></span>
                                <h6>Web Development</h6>
                                <MainBtn BtnText="Get a Quote" btnOnclickHandler={toggleQuoteForm} />
                            </div>
                            <div className="service-icon">
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" className="bi bi-google-play" viewBox="0 0 16 16">
                                    <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96 2.694-1.586Zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055l7.294-4.295ZM1 13.396V2.603L6.846 8 1 13.396ZM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27Z" />
                                </svg></span>
                                <h6>Mobile Apps - Android & iOS</h6>
                                <MainBtn BtnText="Get a Quote" btnOnclickHandler={toggleQuoteForm} />
                            </div>
                            <div className="service-icon">
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" className="bi bi-window-fullscreen" viewBox="0 0 16 16">
                                    <path d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
                                    <path d="M.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H.5ZM1 5V2h14v3H1Zm0 1h14v8H1V6Z" />
                                </svg></span>
                                <h6>Desktop Applications</h6>
                                <MainBtn BtnText="Get a Quote" btnOnclickHandler={toggleQuoteForm} />
                            </div>
                            <div className="service-icon">
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" className="bi bi-braces" viewBox="0 0 16 16">
                                    <path d="M2.114 8.063V7.9c1.005-.102 1.497-.615 1.497-1.6V4.503c0-1.094.39-1.538 1.354-1.538h.273V2h-.376C3.25 2 2.49 2.759 2.49 4.352v1.524c0 1.094-.376 1.456-1.49 1.456v1.299c1.114 0 1.49.362 1.49 1.456v1.524c0 1.593.759 2.352 2.372 2.352h.376v-.964h-.273c-.964 0-1.354-.444-1.354-1.538V9.663c0-.984-.492-1.497-1.497-1.6zM13.886 7.9v.163c-1.005.103-1.497.616-1.497 1.6v1.798c0 1.094-.39 1.538-1.354 1.538h-.273v.964h.376c1.613 0 2.372-.759 2.372-2.352v-1.524c0-1.094.376-1.456 1.49-1.456V7.332c-1.114 0-1.49-.362-1.49-1.456V4.352C13.51 2.759 12.75 2 11.138 2h-.376v.964h.273c.964 0 1.354.444 1.354 1.538V6.3c0 .984.492 1.497 1.497 1.6z" />
                                </svg></span>
                                <h6>Coding School</h6>
                                <MainBtn BtnText="Sign Up" btnOnclickHandler={toggleScholar} />
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="service-icon">
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" className="bi bi-megaphone" viewBox="0 0 16 16">
                                    <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
                                </svg></span>
                                <h6>Public Speaking</h6>
                                <MainBtn BtnText="Get a Quote" btnOnclickHandler={toggleQuoteForm} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="additional-services-container">
                <div className="additional-services-content">
                    <div className="services-box">
                        {Contents.map((item: any, index: number) => {
                            return <ContentCard key={index} ImagePath="" Heading={item.header} Message={item.message} />
                        })}
                    </div>
                </div>
            </div>
            <div className="get-quote-container">
                <div className="get-quote-content">
                    <div className="get-quote-content-form">
                        <h4>Get a Quote</h4>

                        <div className="get-quote-form">
                            <fieldset className="form-group">
                                <input type="text" placeholder="What's this about ?" onChange={(event: any) => setSubject((event.target as HTMLInputElement).value)} value={Subject} id="Subject" name="Subject" className="form-control" />
                            </fieldset>
                            <fieldset className="form-group">
                                <input type="text" placeholder="Your Names - like Full Names - first and last name" onChange={(event: any) => setNames((event.target as HTMLInputElement).value)} value={Names} id="Names" name="Names" className="form-control" />
                            </fieldset>
                            <fieldset className="form-group">
                                <input type="email" placeholder="Your valid email address, so I can get back to you" onChange={(event: any) => setEmail((event.target as HTMLInputElement).value)} value={Email} id="Email" name="Email" className="form-control" />
                            </fieldset>
                            <fieldset className="form-group">
                                <select value={Service} onChange={(event: any) => setService((event.target as HTMLInputElement).value)} className="form-control" id="Service" name="Service">
                                    <optgroup>
                                        <option value="">Select Service Type</option>
                                        <option value="Web Development">Web Development</option>
                                        <option value="Desktop Apps Development">Desktop Apps Development</option>
                                        <option value="Mobile Development">Mobile Development</option>
                                        <option value="Public Speaking">Public Speaking</option>
                                    </optgroup>
                                </select>
                            </fieldset>
                            <fieldset className="form-group">
                                <select value={ServicePlan} onChange={(event: any) => setServicePlan((event.target as HTMLInputElement).value)} className="form-control" id="ServicePlan" name="ServicePlan">
                                    <optgroup>
                                        <option value="">Select Service Plan</option>
                                        <option value="Rent to Own">Rent to Own</option>
                                        <option value="Full Ownership">Full Ownership</option>
                                    </optgroup>
                                </select>
                            </fieldset>
                            <fieldset className="form-group">
                                <textarea placeholder="Your message here . . ." onChange={(event: any) => setMessage((event.target as HTMLInputElement).value)} value={Message} id="Message" name="Message" className="form-control"></textarea>
                            </fieldset>
                            <MainBtn btnOnclickHandler={submitQuote} BtnText={"Submit"} />
                        </div>
                        <br />
                        <div className="additional-contacts">
                            <h4>Or use these</h4>
                            <p><b>phone</b>: +2767 958 1135 or +2761 4125 819</p>
                            <p><b>email</b>: <a href="mailto:hello@treasuremkhonto.co.za">hello@treasuremkhonto.co.za</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
}

export default ServicesScreen;