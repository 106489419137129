import { EnvironmentVariables } from "./Environment";

import Quote from "./interfaces/Quote";
import { User } from "./interfaces/User";
import { Views } from "./interfaces/Views";
import { Likes } from "./interfaces/Likes";
import { IBanking } from "./interfaces/IBanking";
import { Comment } from "./interfaces/Comment";
import { Article } from "./interfaces/Article";
import { Followers } from "./interfaces/Followers";
import { Downloads } from "./interfaces/Downloads";
import { ContactForm } from "./interfaces/ContactForm";
import { Withdrawals } from "./interfaces/Withdrawals";
import { INotification } from "./interfaces/INotification";
import { IReferral } from "./interfaces/IReferral";
import { ICommentLike } from "./interfaces/ICommentLike";
import { IFavourite } from "./interfaces/IFavourite";
import { ICommentReply } from "./interfaces/ICommentReply";

export class MainServices {
    AppData: any;
    MainUrl: string;
    NotificationUrl: string;

    constructor() {
        this.AppData = EnvironmentVariables;
        this.MainUrl = this.AppData.URLs.MainUrl;
        this.NotificationUrl = this.AppData.URLs.NotificationUrl;
    }

    ContactUs = async (params: ContactForm) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Contact.SendUserEmail}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    CreateQuote = async (params: Quote) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Quotes.CreateQuote}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    CreateSubscription = async (params: { Email: string }) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Subscriptions.CreateSubscription}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    CreateUser = async (params: User) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.User.CreateUser}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    UpdateUser = async (params: User) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.User.UpdateUser}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    ResetPassword = async (params: User) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.User.ResetPassword}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    UpdateImage = async (params: User) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.User.UpdateImage}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    UpdatePassword = async (params: User) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.User.UpdatePassword}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    ValidateUser = async (params: User) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.User.ValidateUser}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    CreateComment = async (params: Comment) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Comment.CreateComment}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetComments = async (params: Comment) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Comment.GetComments}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetAllComments = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Comment.GetComments}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    DeleteComments = async (params: Comment) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Comment.DeleteComment}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    SetBankingDetails = async (params: IBanking) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Banking.SetBankingDetails}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    UpdateBankingDetails = async (params: IBanking) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Banking.UpdateBankingDetails}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetUserBankingDetails = async (params: IBanking) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Banking.GetUserBankingDetails}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetBalance = async (params: User) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Banking.GetBalance}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetUser = async (params: User) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.User.GetUser}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    SearchUser = async (params: User) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.User.SearchUser}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    CreateArticle = async (params: Article) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Article.CreateArticle}`, {
            method: "POST",
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    PublishArticle = async (params: Article) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Article.PublishArticle}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetArticleStats = async (params: User) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Article.GetArticleStats}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    UpdateArticle = async (params: Article) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Article.UpdateArticle}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    ReportArticle = async (params: Article) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Article.ReportArticle}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetUserArticles = async (params: Article) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Article.GetUserArticles}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetArticles = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Article.GetArticles}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    GetArticle = async (params: Article) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Article.GetArticle}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetUnplishedArticles = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Article.GetUnplishedArticles}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then((response: any) => response.json())
    }

    GetViews = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.ArticleView.GetViews}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    GetArticleViews = async (params: Views) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.ArticleView.GetArticleViews}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    SetViews = async (params: Views) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.ArticleView.SetViews}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetLikes = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Like.GetLikes}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    SetLikes = async (params: Likes) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Like.SetLike}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    DeleteLike = async (params: Likes) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Like.DeleteLike}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    SetDisLike = async (params: Likes) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Like.SetDisLike}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetArticleLikes = async (params: Likes) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Like.GetArticleLikes}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    FollowAuthor = async (params: Followers) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Follower.CreateFollower}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    DeleteFollower = async (params: Followers) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Follower.DeleteFollower}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetFollowers = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Follower.GetFollowers}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    GetDownloads = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Download.GetDownloads}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    SetDownloads = async (params: Downloads) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Download.SetDownloads}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetUserWithdrawals = async (params: Withdrawals) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Withdrawals.GetUserWithdrawals}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    CreateWithdrawal = async (params: Withdrawals) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Withdrawals.CreateWithdrawal}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    // get authors 
    GetAuthors = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.User.GetAuthors}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    // get counts 
    GetUsersCount = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.User.GetUsersCount}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    GetAuthorsCount = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.User.GetAuthorsCount}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    GetArticlesCount = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Article.GetArticlesCount}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    GetCommentsCount = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Comment.GetCommentsCount}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    GetViewsCount = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.ArticleView.GetViewsCount}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    GetLikesCount = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Like.GetLikesCount}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    GetFollowersCount = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Follower.GetFollowersCount}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    GetUnpublishedCount = async () => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Article.GetUnpublishedCount}`, {
            method: "GET",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
        })
            .then((response: any) => response.json())
    }

    GetUserFollowers = async (params: Followers) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Follower.GetUserFollowers}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    EmailAllUsers = async (params: ContactForm) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Contact.EmailAllUsers}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetNotifications = async (params: INotification) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Notifications.GetNotifications}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    CreateNotification = async (params: INotification) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Notifications.CreateNotification}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    UpdateNotification = async (params: INotification) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Notifications.UpdateNotification}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    CreateReferral = async (params: IReferral) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Referrals.CreateReferral}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }


    CreateCommentLike = async (params: ICommentLike) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.CommentsLikes.CreateCommentLike}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetArticleCommentsLikes = async (params: ICommentLike) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.CommentsLikes.GetArticleCommentsLikes}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }



    CreateFavourite = async (params: IFavourite) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Favourites.CreateFavourite}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetUserFavourites = async (params: User) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Favourites.GetUserFavourites}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    RemoveFavourite = async (params: IFavourite) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Favourites.RemoveFavourite}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    IfUserLovesArticle = async (params: IFavourite) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.Favourites.IfUserLovesArticle}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }


    CreateCommentReply = async (params: ICommentReply) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.CommentsReplies.CreateCommentReply}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    GetArticleCommentsReplies = async (params: Article) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.CommentsReplies.GetArticleCommentsReplies}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }

    DeleteReply = async (params: ICommentReply) => {
        return fetch(`${this.MainUrl + this.AppData.EndPoints.CommentsReplies.DeleteReply}`, {
            method: "POST",
            headers: {
                "Accept": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(params)
        })
            .then((response: any) => response.json())
    }
}

