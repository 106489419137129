import { useFetch } from "../../../../../hooks/useFetch"

import { Comment } from "../../../../../utils/interfaces/Comment";
import { Results } from "../../../../../utils/interfaces/Results";

import { EnvironmentVariables } from "../../../../../utils/Environment";
import { ICommentReply } from "../../../../../utils/interfaces/ICommentReply";

export const useCommentsData = () => {
    const EndPoints = EnvironmentVariables.EndPoints;

    const { runFetchPost } = useFetch();

    const deleteComment = async (SelectedComment: Comment) => {
        const results: Results = await runFetchPost(SelectedComment, EndPoints.Comment.DeleteComment);

        return results;

    }

    const addComment = async (SelectedComment: Comment) => {
        const results: Results = await runFetchPost(SelectedComment, EndPoints.Comment.CreateComment);

        return results;
    }

    const addCommentReply = async (SelectedCommentReply: ICommentReply) => {
        const results: Results = await runFetchPost(SelectedCommentReply, EndPoints.CommentsReplies.CreateCommentReply);

        return results;
    }

    const deleteCommentReply = async (SelectedCommentReply: ICommentReply) => {
        const results: Results = await runFetchPost(SelectedCommentReply, EndPoints.CommentsReplies.DeleteReply);

        return results;
    }

    const buildComment = (aid: number, date: string, text: string, id: number, cid: number) => {
        return cid > 0 ? {
            ArticleID: aid,
            DateCreated: date,
            Text: text,
            CommentID: cid,
            UserID: id,
        } : {
            ArticleID: aid,
            DateCreated: date,
            Text: text,
            UserID: id,
        } as Comment;
    }

    return { deleteComment, addComment, buildComment, addCommentReply, deleteCommentReply };
}