import React from "react";

import { Article } from "../../../utils/interfaces/Article";
import { EnvironmentVariables } from "../../../utils/Environment";

import MainBtn from "../../../shared/MainBtn";

export const CardRibbon: React.FC<{
    ItemsList: Article[],
    MaxCount: number,
    openArticle: (article: Article) => void
}> = ({ ItemsList,
    MaxCount,
    openArticle }): JSX.Element => {
        const ImagesUrl = EnvironmentVariables.URLs.AppUrl;

        return <>
            {ItemsList.map((article: Article, index: number) => {
                return <div className="recently-card" key={index} style={{ background: "url(" + ImagesUrl + "/images/" + article.ImageName + ")" }}>
                    <div className="recently-card-backdrop">
                        <div className="recently-card-header">{article.UserNames}</div>
                        <div className="recently-card-body">
                            <h4>{article.ArticleName.length > MaxCount ? article.ArticleName.substring(0, MaxCount) + ".." : article.ArticleName}</h4>
                        </div>
                        <div className="recently-card-footer">
                            <MainBtn BtnText="Read More" btnOnclickHandler={() => openArticle(article)} />
                        </div>
                    </div>
                </div>
            })}
        </>
    }
