import React, { useState, useEffect } from "react";

import MainBtn from "../../../../shared/MainBtn";
import { NavMenu } from "../../shared/NavMenu";
import CommentForm from "../../components/CommentForm";
import CommentHolder from "../../components/CommentHolder";

import { useNavigate, useParams } from "react-router-dom";

import { EnvironmentVariables } from "../../../../utils/Environment";

import { useDispatch, useSelector } from "react-redux";

import { User } from "../../../../utils/interfaces/User";
import { Likes } from "../../../../utils/interfaces/Likes";
import { Results } from "../../../../utils/interfaces/Results";
import { Comment } from "../../../../utils/interfaces/Comment";
import { Article } from "../../../../utils/interfaces/Article";
import { Followers } from "../../../../utils/interfaces/Followers";
import { ICommentLike } from "../../../../utils/interfaces/ICommentLike";
import { ICommentReply } from "../../../../utils/interfaces/ICommentReply";

import ModalBox from "../../../../shared/ModalBox";
import DialogBox from "../../../../shared/DialogBox";
import { FooterMenu } from "../../shared/FooterMenu";

import { useFetch } from "../../../../hooks/useFetch";
import { useLikesData } from "./hooks/useLikesData";
import { useCommentsData } from "./hooks/useCommentsData";
import { useDateObject } from "../../../../hooks/useDateObject";
import { useFollowersData } from "./hooks/useFollowersData";
import { setPreviewUser, setUserFollowers } from "../../../../utils/redux/Actions/MainActions";
import { useUserPreview } from "../../hooks/useUserPreview";
import { Mention } from "../../../../utils/interfaces/Mentions";
import { Views } from "../../../../utils/interfaces/Views";
import { IFavourite } from "../../../../utils/interfaces/IFavourite";

export const ArticleView: React.FC<{}> = (): JSX.Element => {
    let counter = 0;

    const EndPoints = EnvironmentVariables.EndPoints;
    const ImagesUrl = EnvironmentVariables.URLs.AppUrl;

    // hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pathParams: any = useParams();
    const { runFetchPost } = useFetch();
    const { addFollower, buildFollower } = useFollowersData();
    const { getNowDateAndTime, getNormalDate } = useDateObject();
    const { addArticleLike, addCommentLike, buildLike } = useLikesData();
    const { deleteComment, addComment, buildComment, addCommentReply, deleteCommentReply } = useCommentsData();

    const UserDetails: User = useSelector((state: any) => state.UserDetails);
    const Authenticated: number = useSelector((state: any) => state.Authenticated);
    const UserFollowersList: any = useSelector((state: any) => state.UserFollowers);
    const SelectedArticle: Article = useSelector((state: any) => state.CurrentArticle);

    // data states
    const [ArticleData, setArticleData] = useState<any>({});
    const [LikesList, setLikesList] = useState<Likes[]>([]);
    const [DislikesList, setDislikesList] = useState<Likes[]>([]);
    const [CommentsList, setCommentsList] = useState<Comment[]>([]);
    const [CurrentArticle, setCurrentArticle] = useState<Article>(SelectedArticle);
    const [CommentsRepliesList, setCommentsRepliesList] = useState<ICommentReply[]>([]);

    const [CommentsLikes, setCommentsLikes] = useState<ICommentLike[]>([]);

    const [FollowersList, setFollowersList] = useState<Followers[]>([]);
    const [FavouritesList, setFavouritesList] = useState<IFavourite[]>([]);

    const [CommentText, setCommentText] = useState("");

    const [ModalHeader, setModalHeader] = useState("");
    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    // boolean states

    const [ClearText, setClearText] = useState(false);
    const [IfUserLoves, setIfUserLoves] = useState(false);
    const [ModalStatus, setModalStatus] = useState(false);
    const [DialogStatus, setDialogStatus] = useState(false);

    const [IsUserFollowing, setIsUserFollowing] = useState(false);
    const [IsLoveProcessing, setIsLoveProcessing] = useState(false);

    const [PostingLike, setPostingLike] = useState(false);
    const [CreatingFollower, setCreatingFollower] = useState(false);

    const [PostingComment, setPostingComment] = useState(false);
    const [IsCommentLiking, setIsCommentLiking] = useState(false);
    const [IsReplyingComment, setIsReplyingComment] = useState(false);

    useEffect(() => {
        if (CurrentArticle.ID > 0)
            getArticleData();
        else
            getArticle(pathParams.id);

    }, []);

    useEffect(() => {
        if (Authenticated > 0 && CurrentArticle.UserID !== Authenticated)
            checkIfFollowingAuthor(FollowersList);
    }, [ModalStatus]);

    useEffect(() => {
        counter++;
        if (counter == 1)
            setTimeout(() => {
                setArticleView();
            }, 45000);
    }, [CurrentArticle !== undefined]);

    const setArticleView = async () => {
        const params: Views = {} as Views;

        if (CurrentArticle.ID > 0) {
            params.ArticleID = SelectedArticle.ID;
            params.DateViewed = getNormalDate();

            await runFetchPost(params, EndPoints.ArticleView.SetViews);
        }
    }

    const getArticle = async (id: number) => {
        const article: Article = {} as Article;

        article.ID = id;

        const getResults: Results = await runFetchPost(article, EndPoints.Article.GetArticleData);

        if (getResults.Error) {
            setUpDialogValues(true, "Error", getResults.ErrorDetail);
        } else {
            setLikesList(getResults.Results.LikesCount);
            setCurrentArticle(getResults.Results.Article);
            setDislikesList(getResults.Results.DislikesCount);

            setArticleData(getResults.Results);
            setCommentsList(getResults.Results.Comments);
            setCommentsLikes(getResults.Results.CommentsLikes);
            setCommentsRepliesList(getResults.Results.CommentReplies);

            setFollowersList(getResults.Results.Followers);


            document.title = getResults.Results.Article.ArticleName + " | " + getResults.Results.Article.UserNames;

            if (Authenticated > 0 && CurrentArticle.UserID !== Authenticated)
                checkIfFollowingAuthor(getResults.Results);
        }
    }

    const followAuthor = async () => {
        if (CurrentArticle.ID > 0) {
            if (Authenticated < 1) {
                setUpDialogValues(true, "Error", "Please sign in first.");
            } else if (IsUserFollowing) {
                setUpDialogValues(true, "Error", "You are already following " + CurrentArticle.UserNames);
            } else {
                setCreatingFollower(true);
                const params: any = buildFollower(SelectedArticle.UserID, Authenticated);

                const FollowerResults: Results = await addFollower(params);

                if (FollowerResults.Error) {
                    setUpDialogValues(true, "Error", FollowerResults.ErrorDetail);
                } else {
                    getArticleData();
                    setUpDialogValues(true, "Success", "You are now following " + SelectedArticle.UserNames + ". You will be notified when they publish new articles and when there are new comments on their articles.");

                    setCreatingFollower(false);

                }
            }
        }
    }

    const getArticleData = async () => {
        const params: Article = CurrentArticle;

        const getResults: Results = await runFetchPost(params, EndPoints.Article.GetArticleData);

        if (getResults.Error) {
            setUpDialogValues(true, "Error", getResults.ErrorDetail);
        } else {
            setLikesList(getResults.Results.LikesCount);
            setDislikesList(getResults.Results.DislikesCount);

            setArticleData(getResults.Results);
            setCommentsList(getResults.Results.Comments);
            setFavouritesList(getResults.Results.Favourites);
            setCommentsLikes(getResults.Results.CommentsLikes);
            setCommentsRepliesList(getResults.Results.CommentReplies);

            setFollowersList(getResults.Results.Followers);

            document.title = getResults.Results.Article.ArticleName + " | " + getResults.Results.Article.UserNames;

            if (getResults.Results.Favourites.length > 0 && Authenticated > 0) {
                const ifILovedThis = getResults.Results.Favourites.find((item: IFavourite) => {
                    return (item.UserID == Authenticated);
                });

                if (ifILovedThis)
                    setIfUserLoves(true);
            }

            if (Authenticated > 0 && SelectedArticle.UserID !== Authenticated)
                checkIfFollowingAuthor(getResults.Results.Followers);

        }
    }

    const checkIfFollowingAuthor = (list: Followers[]) => {
        if (list.length > 0) {

            const me = list.find((item: Followers) => {
                return (item.UserID == UserDetails.ID);
            });

            if (me)
                setIsUserFollowing(true);

        }
    }

    const toggleComments = () => {
        (document.querySelector(".article-comments") as HTMLDivElement).scrollIntoView();
        (document.querySelector(".article-comments") as HTMLDivElement).scrollIntoView({ block: "end" });
        (document.querySelector(".article-comments") as HTMLDivElement).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    const likeArticle = async (type: number) => {
        if (CurrentArticle.ID > 0) {
            if (Authenticated > 0) {
                setPostingLike(true);

                const list = (type == 1) ? DislikesList : LikesList;

                const isLiked: any = list.find((item: Likes) => {
                    return (item.UserID == Authenticated);
                });

                const params: any = buildLike(SelectedArticle.ID, 0, Authenticated, getNowDateAndTime(), type);

                if (isLiked)
                    params.ID = isLiked.ID;

                const likingResults: Results = await addArticleLike(params);

                if (likingResults.Error) {
                    setUpDialogValues(true, "Error", likingResults.ErrorDetail);
                } else {
                    getArticleData();
                }

                setPostingLike(false);

            } else {
                setPostingLike(false);
                setUpDialogValues(true, "Error", "Please sign in first.");
            }
        }
    }

    const createComment = async (Mentioned: any[]) => {

        if (CurrentArticle.ID > 0) {
            if (Authenticated < 1) {
                setUpDialogValues(true, "Error", "Please sign in to add your comment.");
            } else if (CommentText == "") {
                setUpDialogValues(true, "Error", "Please provide a comment");
            } else {
                setPostingComment(true);

                const params: any = buildComment(CurrentArticle.ID, getNowDateAndTime(), CommentText, Authenticated, 0);

                const CommentResults: Results = await addComment(params);

                if (CommentResults.Error) {
                    setPostingComment(false);
                    setUpDialogValues(true, "Error", CommentResults.ErrorDetail);
                } else {
                    setCommentText("");
                    params.ID = CommentResults.Results;
                    params.UserNames = UserDetails.FirstName + " " + UserDetails.LastName;
                    setCommentsList([...CommentsList, params]);

                    createMention(Mentioned, CommentResults.Results, 1);

                    setPostingComment(false);
                }
            }
        }
    }

    const createMention = async (Mentioned: any[], id: number, type: number) => {
        const mentions: Mention[] = [];

        if (Mentioned.length > 0) {
            Mentioned.forEach((item: any) => {
                mentions.push({
                    ArticleID: CurrentArticle.ID,
                    UserID: item.UserID,
                    CommentID: type == 1 ? id : 0,
                    CommentReplyID: type == 2 ? id : 0,
                    ID: 0,
                    FollowerID: Authenticated
                });
            });

            if (mentions.length > 0) {
                const postResults: Results = await runFetchPost(mentions, EndPoints.Mentions.CreateMention);

                if (postResults.Error)
                    setUpDialogValues(true, "Error", postResults.ErrorDetail);
            }
        }
    }

    const likeComment = async (type: number, id: number) => {
        if (Authenticated > 0) {
            setIsCommentLiking(true);

            const isLiked: any = CommentsLikes.find((like: ICommentLike) => {
                return (like.UserID == Authenticated && like.CommentID == id);
            });

            const params: any = buildLike(SelectedArticle.ID, id, Authenticated, getNowDateAndTime(), type);

            if (isLiked)
                params.ID = isLiked.ID;

            const commentLikeResults: Results = await addCommentLike(params);

            if (!commentLikeResults.Error) {
                getArticleData();
            }

            setIsCommentLiking(false);

        } else {
            setUpDialogValues(true, "Error", "Please sign in first.");
        }
    }

    const removeComment = async (id: number) => {
        const comments = CommentsList;
        const SelectedComment = comments.find((item: Comment) => {
            return (item.ID == id);
        });

        if (Authenticated < 1) {
            setUpDialogValues(true, "Error", "Please sign in first to delete a comment");
        } else {
            if (SelectedComment) {
                const results: Results = await deleteComment(SelectedComment);

                if (results.Error)
                    setUpDialogValues(true, "Error", results.ErrorDetail);
                else
                    getArticleData();
            }
        }
    }

    const createCommentReply = async (text: string, id: number, Mentioned: any[]) => {
        if (Authenticated > 0) {
            setIsReplyingComment(true);

            const params: any = buildComment(CurrentArticle.ID, getNowDateAndTime(), text, Authenticated, id);

            const commentReplyResults: Results = await addCommentReply(params);

            if (!commentReplyResults.Error) {
                createMention(Mentioned, commentReplyResults.Results, 2);
                setClearText(true);
                getArticleData();
            }

            setIsReplyingComment(false);
        } else {
            setUpDialogValues(true, "Error", "Please sign in first.");
        }
    }

    const removeCommentReply = async (id: number) => {
        if (Authenticated < 1) {
            setUpDialogValues(true, "Error", "Please sign in first to delete a comment");
        } else {
            const replies = CommentsRepliesList;
            const params: ICommentReply = {} as ICommentReply;
            const SelectedReply = replies.find((item: ICommentReply) => {
                return (item.ID == id);
            });

            if (SelectedReply) {
                setIsReplyingComment(true);

                params.ID = SelectedReply.ID;

                const DeleteCommentReplyResults: Results = await deleteCommentReply(SelectedReply);

                setIsReplyingComment(false);
                if (DeleteCommentReplyResults.Error)
                    setUpDialogValues(true, "Error", DeleteCommentReplyResults.ErrorDetail);
                else
                    getArticleData();
            }
        }
    }

    const previewReplyUser = async (reply: ICommentReply) => {
        const results: Results = await runFetchPost({ ID: reply.UserID }, EndPoints.User.GetUser);

        if (results.Error) {
            setUpDialogValues(true, "Error", results.ErrorDetail);
        } else {
            dispatch(setPreviewUser(results.Results));
            navigate("/preview/" + results.Results.User.FirstName + " " + results.Results.User.LastName);
        }
    }

    const previewUser = async (comment: Comment) => {
        const results: Results = await runFetchPost({ ID: comment.UserID }, EndPoints.User.GetUser);

        if (results.Error) {
            setUpDialogValues(true, "Error", results.ErrorDetail);
        } else {
            dispatch(setPreviewUser(results.Results));
            navigate("/preview/" + results.Results.User.FirstName + " " + results.Results.User.LastName);
        }
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    const closeDialog = () => {
        setDialogStatus(false);
    }

    const closeModal = () => {
        setModalStatus(false);
    }

    const addFavorite = async () => {
        const params: IFavourite = {} as IFavourite;

        if (Authenticated > 0) {
            params.ArticleID = CurrentArticle.ID;
            params.UserID = Authenticated;

            setIsLoveProcessing(true);

            const userLovesResults: Results = await runFetchPost(params, EndPoints.Favourites.CreateFavourite);

            if (!userLovesResults.Error) {
                getArticleData();
                setIsLoveProcessing(false);
            }
        } else {
            setUpDialogValues(true, "Error", "Please sign in first.");
        }
    }

    return <>
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />

        <ModalBox Header={ModalHeader} closeModal={closeModal} Status={ModalStatus} />

        <NavMenu setModalHeader={setModalHeader} setModalStatus={setModalStatus} />

        <section>
            <div className="blog-article-container">
                <div className="blog-article-content">
                    <div className="blog-article-header">
                        <div className="blog-article-name" style={{ pointerEvents: CreatingFollower ? "none" : "all" }}>
                            <h4>{Object.keys(CurrentArticle).length > 0 && (CurrentArticle.ArticleName.length > 47 ? CurrentArticle.ArticleName.substring(0, 47) : CurrentArticle.ArticleName)}</h4>
                            <p>{Object.keys(CurrentArticle).length > 0 && CurrentArticle.TopicName} - {Object.keys(CurrentArticle).length > 0 && CurrentArticle.ArticleDate} - {FollowersList.length} {FollowersList.length >= 0 && FollowersList.length !== 1 ? "Followers" : "Follower"}</p>
                            {(Authenticated > 0 &&
                                Authenticated !== CurrentArticle.UserID
                                && CurrentArticle.UserID !== undefined)
                                &&
                                <div className="follow-and-favorite" style={{ display: "flex", justifyContent: "space-between", pointerEvents: IsLoveProcessing || CreatingFollower ? "none" : "all" }}>
                                    <MainBtn BtnText={IsUserFollowing ? "You are following " + CurrentArticle.UserNames.toString().substring(0, 12) : "Follow " + CurrentArticle.UserNames.toString().substring(0, 12)} btnOnclickHandler={followAuthor} />
                                    <br />{(Authenticated > 0 && Authenticated != CurrentArticle.UserID) && <span onClick={addFavorite}><i style={{ color: IfUserLoves ? "#e6005c" : "#000000" }} className="bi bi-heart-pulse-fill"></i> <small>{FavouritesList.length}</small></span>}
                                </div>
                            }
                        </div>
                        <div className="blog-article-image" style={{ background: "url(" + ImagesUrl + "/images/" + CurrentArticle.ImageName + ")" }}></div>
                        <p className="article-author" style={{ padding: "5px 0 0 0" }}>by {CurrentArticle.UserNames}</p>
                        <div className="blog-header-options">
                            <span style={{ pointerEvents: PostingLike ? "none" : "all" }}

                                onClick={() => likeArticle(1)} className={LikesList.find((item: Likes) => { return (item.UserID == 1 && item.ArticleLike > 0) }) ? "article-is-liked" : ""}>
                                <i className="bi bi-hand-thumbs-up-fill"></i>
                                <small>{LikesList.length} {LikesList.length >= 0 && LikesList.length !== 1 ? " Likes" : " Like"}</small></span>
                            <span style={{ pointerEvents: PostingLike ? "none" : "all" }}
                                onClick={() => likeArticle(2)} className={DislikesList.find((item: Likes) => { return (item.UserID == 1 && item.Dislike > 0) }) ? "article-is-disliked" : ""}>
                                <i className="bi bi-hand-thumbs-down-fill"></i> <small>{DislikesList.length}
                                    {DislikesList.length >= 0 && DislikesList.length !== 1 ? " Dislikes" : " Dislike"}</small></span>
                            <span onClick={toggleComments}><i className="bi bi-chat-fill"></i> <small>{CommentsList.length} {CommentsList.length >= 0 && CommentsList.length !== 1 ? "Comments" : "Comment"}</small></span>
                            <span><i className="bi bi-eye-fill"></i> <small>{ArticleData.ViewsCount} {ArticleData.ViewsCount >= 0 && ArticleData.ViewsCount !== 1 ? "Views" : "Views"}</small></span>
                        </div>
                    </div>
                    <div className="blog-article-body">
                        <pre>{CurrentArticle.Article}</pre>
                    </div>
                    <div className="blog-article-comments">
                        <h4>Join the discussion</h4>
                        <div className="article-comments">
                            <CommentForm FollowersList={UserFollowersList} BtnTextUpdate={PostingComment} createComment={createComment} Comment={CommentText} setComment={setCommentText} />
                            <div className="comments-main-container">
                                <CommentHolder
                                    FollowersList={UserFollowersList}
                                    removeCommentReply={removeCommentReply}
                                    IsReplyingComment={IsReplyingComment}
                                    createCommentReply={createCommentReply}
                                    IsCommentLiking={IsCommentLiking}
                                    CommentsRepliesList={CommentsRepliesList}
                                    likeComment={likeComment}
                                    ClearText={ClearText}
                                    CommentsLikes={CommentsLikes}
                                    previewUser={previewUser}
                                    previewReplyUser={previewReplyUser}
                                    removeComment={removeComment}
                                    CommentsList={CommentsList} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </section>
        <br /><br />
        <FooterMenu setModalStatus={setModalStatus} setModalHeader={setModalHeader} />
    </>
}