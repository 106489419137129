import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import $ from "jquery";
import Logo from "../assets/images/logo_png.jpeg";

import DialogBox from "./DialogBox";

// utils
import { useDispatch } from "react-redux";
import { MainServices } from "../utils/Services";
import { Results } from "../utils/interfaces/Results";
import { isAuthenticated, setUserDetails, setUserFollowers } from "../utils/redux/Actions/MainActions";
import { Followers } from "../utils/interfaces/Followers";

const NavBar: React.FC<{}> = (): JSX.Element => {
    const dispatch = useDispatch();

    const Services: MainServices = new MainServices();

    const [DialogHeader, setDialogHeader] = useState("");
    const [DialogMessage, setDialogMessage] = useState("");

    const [DialogStatus, setDialogStatus] = useState(false);

    const pathname = useLocation();
    const [CPath, setCpath] = useState("");

    const toggleNavMenu = () => {
        $(".actual-nav").slideToggle(500);
    }

    useEffect(() => {
        if (pathname.pathname == "/write" || pathname.pathname == "/banking" || pathname.pathname == "/profile")
            setCpath("/blog");
        else
            setCpath(pathname.pathname);

        window.onload = () => {
            setTimeout(() => {
                let user: any = sessionStorage.getItem("user");
                if (user !== null) {
                    user = JSON.parse(user);
                    dispatch(setUserDetails(user));
                    dispatch(isAuthenticated(user.ID));
                    getUserFollowers(user.ID);
                }
            }, 3000);
        }
    }, []);

    const getUserFollowers = async (id: number) => {
        const follower: Followers = {} as Followers;

        follower.AuthorID = id;

        const FollowersResults: Results = await Services.GetUserFollowers(follower);

        if (FollowersResults.Error) {
            setUpDialogValues(true, "Error", FollowersResults.ErrorDetail);
        } else {
            dispatch(setUserFollowers(FollowersResults.Results));
        }
    }


    const closeDialog = () => {
        setDialogStatus(false);
    }

    const setUpDialogValues = (status: boolean, header: string, message: string) => {
        setDialogStatus(status);
        setDialogHeader(header);
        setDialogMessage(message);
    }

    return <>
        <DialogBox
            closeDialog={closeDialog}
            DialogMessage={DialogMessage}
            DialogStatus={DialogStatus}
            DialogHeader={DialogHeader} />
        <header style={HeaderDefaultStyling.header}>
            <div className="header-container">
                <div className="header-content" style={HeaderDefaultStyling.headerContent}>
                    <div className="app-name" style={HeaderDefaultStyling.appName}>
                        <span><img src={Logo} alt="logo" /></span>
                        <small style={HeaderDefaultStyling.slogan}>Purpose and Education Expert - a Pursuit of Purpose</small>
                    </div>
                    <div className="app-nav"></div>
                    <div className="app-mobile-nav" onClick={toggleNavMenu}>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
            </div>
        </header >
        <div className="header-content-lower" style={{ width: "100%", background: "#333333" }}>
            <div className="actual-nav">
                <Link to="/" className={CPath == "/" ? "active" : ""}>Home</Link>
                <Link to="/about" className={CPath == "/about" ? "active" : ""}>About</Link>
                <Link to="/services" className={CPath == "/services" ? "active" : ""}>Services</Link>
                <Link to="/blog" className={CPath == "/blog" ? "active" : ""}>The Blog</Link>
                <Link to="/downloads" className={CPath == "/downloads" ? "active" : ""} >Downloads</Link>
                <Link to="/contact-us" className={CPath == "/contact-us" ? "active" : ""}>Contact Us</Link>
                <Link to="/write-earn" className={CPath == "/write-earn" ? "active" : ""}>Write & Earn</Link>
            </div>
        </div>
    </>
}

const HeaderDefaultStyling = {
    header: {
        backgroundColor: "#000000",
        padding: 20,
        color: "#ffffff",
        boxShadow: "1px 1px 1px #cccccc",
        borderBottom: "1px solid #e6005c",
    },
    headerContent: {
        width: "90%",
        margin: "0 auto"
    },
    appName: {
        fontSize: 20,
        display: "grid",
    },
    slogan: {
        fontSize: 11,
        color: "#e6005c"
    }
}

export default NavBar;