import { useFetch } from "../../../../../hooks/useFetch";

import { Article } from "../../../../../utils/interfaces/Article";
import { Results } from "../../../../../utils/interfaces/Results";

import { EnvironmentVariables } from "../../../../../utils/Environment";

export const useWriteData = () => {
    const { runFetchPost } = useFetch();
    
    const EndPoints = EnvironmentVariables.EndPoints;

    const addArticle = async (Article: Article) => {
        const results: Results = await runFetchPost(Article, EndPoints.Article.CreateArticle);

        return results;
    }

    const updateArticle = async (Article: Article) => {
        const results: Results = await runFetchPost(Article, EndPoints.Article.UpdateArticle);

        return results;
    }

    const buildArticle = (id: number, text: string, topic: string, name: string, image: string, date: string) => {
        return {
            UserID: id,
            Article: text,
            TopicName: topic,
            ArticleName: name,
            ImageName: image,
            ArticleDate: date
        } as Article;
    }

    return { addArticle, updateArticle, buildArticle };

}