import React from "react";
import { IFavourite } from "../../../../../utils/interfaces/IFavourite";

const FavouritesRibbon: React.FC<{ List: IFavourite[], removeFavourite?: any, toggleArticle: (favourite: IFavourite) => void }> = ({ List, removeFavourite, toggleArticle }) => {
    const ImagePath: string = "https://nhlavutelo.co.za/images/";

    return <>
        {List.length > 0 && List.map((favourite: any, index: number) => {
            return <div onClick={() => toggleArticle(favourite)} className="follow-card" key={index}>
                <div className="follower-content">
                    <div className="follower-image" style={{ background: "url(" + ImagePath + favourite.ImageName + ")" }}></div>
                    <div className="follower-info">
                        <div className="follower-names">
                            <p>{favourite.ArticleName.substring(0, 35)}</p>
                            <p>{favourite.TopicName}</p>
                        </div>
                    </div>
                    <div onClick={() => removeFavourite(favourite)} style={{ color: "red", cursor: "pointer" }} className="follower-options"><i className="bi bi-x-circle-fill"></i></div>
                </div>
            </div>
        })}
    </>
}

export default FavouritesRibbon;